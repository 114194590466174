import React, { Component, lazy, Suspense, useState } from "react";
import {
  Row,
  Col,
  Avatar,
  Input,
  Button,
  Tooltip,
  Select,
  Table,
  Form,
  message,
} from "antd";
import { UserOutlined, BellFilled, EditOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { Link, NavLink, Redirect } from "react-router-dom";
import moment from "moment";
import "./Consumer.css";
import { Container } from "reactstrap";
import CONFIG from "../../../services/config";
import UserContext from "../../../UserContext";
import ReactFileReader from "react-file-reader";
import ApiService from "../../../services/api-services";
import Org from "../Staff/Prasantation/Org";
const { Search } = Input;
var searchItem = [];
var currentDate = moment().format("MM-DD-YYYY");
const Filter = "Filter";

const filterData = (data) =>
  data.map((item) => ({
    key: item,
    value: item,
    text: item,
  }));
export default class dashboard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.handleFiles = this.handleFiles.bind(this);
    var user_id,
      type,
      formData = {};
    if (this.props.location.state != undefined) {
      formData = {
        user_id: this.props.location.state.user_id,
        type: this.props.location.state.type,
        first_name: this.props.location.state.first_name,
      };
    }
    this.state = {
      formData: formData,
      adminFullyLoaded: false,
      isFetchSpeciality: false,
      isLoading: false,
      orgData: [],
      status: 0,
      adminData: [],
      formType: type,
      // formData: {},
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        user_id: "",
        org_id: "",
      },
    };
  }

  editBtnClick = (
    user_id,
    first_name,
    middle_name,
    last_name,
    dob,
    address,
    zipcode,
    email,
    mobile,
    gender,
    MRN,
    CIN,
    city,
    state,
    country,
    insurance,
    org_id,
    type
  ) => {
    console.log(user_id);

    this.props.history.push({
      pathname: "/add-consumer",
      state: {
        user_id: user_id,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        dob: dob,
        address: address,
        zipcode: zipcode,
        email: email,
        mobile: mobile,
        gender: gender,
        MRN: MRN,
        CIN: CIN,
        city: city,
        state: state,
        country: country,
        insurance: insurance,
        org_id: org_id,
        type,
      },
    });
  };

  componentDidMount() {
    console.log("hello afya");

    this.fetchOrg();
    this.fetchConsumer();
  }

  fetchConsumer = () => {
    let body = {
      org_id:
        sessionStorage.getItem("rolename") === "Admin"
          ? sessionStorage.getItem("org_id")
          : "",
    };
    console.log("orgDetails", sessionStorage.getItem("org_id"));
    ApiService.adddetails.consumerList(body).then((data) => {
      console.log(data, "res");
      this.setState({ isFetchSpeciality: true, isLoading: true });
      if (data.status === 200) {
        this.setState({ adminFullyLoaded: true, consumerData: data.data });
      }
    });
  };

  fetchOrg = () => {
    ApiService.adddetails.getOrgList().then((data) => {
      if (data.status === 200) {
        //  console.log("tagOrg",data)
        this.setState({ orgData: data.data });
      }
    });
  };

  handleChange = (e, field) => {
    const { formData } = this.state;
    if (field === "org_id") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else {
      this.setState(
        {
          formData: { ...formData, [field]: e.target.value },
        },
        function () {}
      );
    }
  };

  showMessage = () => {
    message.success("Template has been successfully downloaded");
  };

  handleFiles = (files) => {
    const { formData } = this.state;
    console.log(this.state.formData.org_id);
    var reader = new FileReader();
    reader.onload = (e) => {
      //Split csv file data by new line so that we can skip first row which is header
      let jsonData = reader.result.split("\n");
      let data = [];
      jsonData.forEach((element, index) => {
        // console.log(jsonData,'data')
        if (index) {
          //Split csv file data by comma so that we will have column data
          const elementRaw = element.split(",");
          // console.log(element, index);
          let frstpatt = RegExp(/^[a-zA-Z ]*$/).test(elementRaw[0]);
          let midpatt = RegExp(/^[a-zA-Z ]*$/).test(elementRaw[1]);
          let lastpatt = RegExp(/^[a-zA-Z ]*$/).test(elementRaw[2]);
          let dobpatt = RegExp(
            /^(0[1-9]|1[0-2])\-(0[1-9]|1\d|2\d|3[01])\-\d{4}$/
          ).test(elementRaw[3]);
          let emailpatt = RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(elementRaw[4]);
          let pwdpatt = RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
          ).test(elementRaw[5]);
          let genderpatt = RegExp(/^(?:m|M|male|Male|f|F|female|Female)$/).test(
            elementRaw[6]
          );
          let zipcodepatt = RegExp(/^[0-9]*$/).test(elementRaw[11]);
          let phonepatt = RegExp(
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
          ).test(elementRaw[7]);
          if (element) {
            // if(elementRaw[3] > currentDate) {
            //     message.warn('Please enter the valid dob')
            //     return
            // }
            let date1 = new Date(currentDate);
            let date2 = new Date(elementRaw[3]);

            if (date2 >= date1) {
              message.warn("Please enter the valid dob");
              return;
            }

            if (
              elementRaw[0] === "" ||
              elementRaw[2] === "" ||
              elementRaw[3] === "" ||
              elementRaw[4] === "" ||
              elementRaw[5] === "" ||
              elementRaw[6] === ""
            ) {
              message.warn("Please enter all the required details");
              return;
            }

            if (
              dobpatt === false ||
              frstpatt === false ||
              midpatt === false ||
              lastpatt === false ||
              emailpatt === false ||
              pwdpatt === false ||
              genderpatt === false
            ) {
              message.warn(
                "Please make sure you have entered valid information"
              );
              return;
            }

            if (elementRaw[11] !== "" && zipcodepatt === false) {
              message.warn(
                "Please make sure you have entered valid information"
              );
              return;
            }

            if (elementRaw[7] !== "" && phonepatt === false) {
              message.warn(
                "Please make sure you have entered valid information"
              );
              return;
            }

            if (elementRaw[6] === "male" || elementRaw[6] === "Male") {
              elementRaw[6] = 1;
            } else {
              elementRaw[6] = 0;
            }
            let param = {
              first_name: elementRaw[0],
              middle_name: elementRaw[1] ? elementRaw[1] : "",
              last_name: elementRaw[2],
              dob: elementRaw[3],
              email: elementRaw[4],
              password: elementRaw[5],
              gender: elementRaw[6],
              org_id: this.state.formData.org_id,
              mobile: elementRaw[7] ? elementRaw[7] : "",
              address: elementRaw[8] ? elementRaw[8] : "",
              city: elementRaw[9] ? elementRaw[9] : "",
              state: elementRaw[10] ? elementRaw[10] : "",
              zipcode: elementRaw[11] ? elementRaw[11] : "",
              country: elementRaw[12] ? elementRaw[12] : "",
              MRN: "",
              home_phone: "",
              insurance: 1,
              employer: "",
            };
            data.push(param);
          }
        }
      });
      console.log(data, "ddd");

      let userbody = {};
      for (let i = 0; i < data.length; i++) {
        let body = {
          patients: [
            {
              first_name:
                data[i]["first_name"].charAt(0).toUpperCase() +
                data[i]["first_name"].slice(1),
              middle_name:
                data[i]["middle_name"].charAt(0).toUpperCase() +
                data[i]["middle_name"].slice(1),
              last_name:
                data[i]["last_name"].charAt(0).toUpperCase() +
                data[i]["last_name"].slice(1),
              dob: data[i]["dob"],
              email: data[i]["email"],
              password: data[i]["password"],
              gender: data[i]["gender"],
              org_id: data[i]["org_id"],
              mobile: data[i]["mobile"],
              address: data[i]["address"],
              city: data[i]["city"],
              state: data[i]["state"],
              zipcode: data[i]["zipcode"],
              country: data[i]["country"],
              MRN: "",
              home_phone: "",
              insurance: 1,
              employer: "",
            },
          ],
        };
        ApiService.adddetails
          .add_bulk_consumer(body)
          .then((data) => {
            this.setState({ isLoading: false });
            this.setState({
              status: data.status,
            });
            //console.log(this.state.status,'222')
            if (data.status === 200) {
              message.success("Successfully added the consumers");
              this.props.history.push("/consumer-list");
              //window.location.reload(true);
            }
          })

          .catch((error) => {
            this.setState({ isLoading: false });
            if (error.data.status === 401) {
              message.error("authentication failed");
            }
            if (error.data.status === 701) {
              message.error("Something went wrong");
            }
            if (error.data.status === 702) {
              message.error("User already exist");
            }
            if (error.data.status === 709) {
              message.error("Npi already exist");
            }
          });

        //    let body = {
        //         "first_name": data[i]['first_name'].charAt(0).toUpperCase() + data[i]['first_name'].slice(1),
        //         "middle_name": data[i]['middle_name'].charAt(0).toUpperCase() + data[i]['middle_name'].slice(1),
        //         "last_name": data[i]['last_name'].charAt(0).toUpperCase() + data[i]['last_name'].slice(1),
        //         "dob": data[i]['dob'],
        //         "email": data[i]['email'],
        //         "password": data[i]['password'],
        //         "org_id": [data[i]['org_id']],
        //         "gender": data[i]['gender']
        //     }
        // userbody.push(body)
      }

      //    console.log(userbody,'aaa')
      //     ApiService.adddetails.add_bulk_consumer(userbody).then(data => {
      //         this.setState({ isLoading: false })
      //         if (data.status === 200) {
      //             message.success('Successfully added the consumers')
      //            // this.getDoctorList();
      //             this.props.history.push('/consumer-list')
      //            //window.location.reload(true);
      //         }

      //     })

      // .catch(error => {
      //     this.setState({ isLoading: false })
      //     if (error.data.status === 401) {
      //         message.error('authentication failed');
      //     }
      //     if (error.data.status === 701) {
      //         message.error('Something went wrong');
      //     }
      //     if (error.data.status === 702) {
      //         message.error('User already exist');
      //     }
      //     if (error.data.status === 709) {
      //         message.error('Npi already exist');
      //     }
      // });
    };
    console.log("TCL: Dashboard -> reader.readyState", reader.readyState);
    reader.readAsText(files[0]);
  };

  Tablecolumns = () => [
    {
      title: "Consumer Name",
      dataIndex: "name",
      render: (url, record) => {
        return (
          <span>
            {record["first_name"]} {record["last_name"]}
          </span>
        );
      },
      width: "25%",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile",
      render: (url, record) => {
        return <span>{record["mobile"]}</span>;
      },
      width: "25%",
    },
    {
      title: "Organization",
      dataIndex: "organization.name",
      width: "20%",

      filters: filterData(
        this.state.orgData
          .map((item) => item.name)
          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) => {
        return record["organization.name"] === value;
      },
    },
    {
      title: "Email Id",
      dataIndex: "email",
      render: (url, record) => {
        return <span>{record["email"]}</span>;
      },
      width: "25%",
    },
    // {
    //     title: 'Added Date',
    //     dataIndex: 'createdAt',
    //     render: (url, record) => {
    //         return <span>{record["createdAt"]}</span>;
    //       },
    //    // sorter: 'true',
    //     width: '25%',

    // },
    {
      title: "Action",
      dataIndex: [],
      width: "20%",
      render: (url, record) => {
        return (
          <span>
            {
              <li id="table-action">
                {console.log(record, "cons")}
                <EditOutlined
                  id="table-edit"
                  onClick={() =>
                    this.editBtnClick(
                      record["user_id"],
                      record["first_name"],
                      record["middle_name"],
                      record["last_name"],
                      record["dob"],
                      record["address"],
                      record["zipcode"],
                      record["email"],
                      record["mobile"],
                      record["gender"],
                      record["MRN"],
                      record["CIN"],
                      record["city"],
                      record["state"],
                      record["country"],
                      record["insurance"],
                      record["org_id"],
                      "edit"
                    )
                  }
                ></EditOutlined>
              </li>
            }
          </span>
        );
      },
    },
  ];

  render() {
    const { consumerData, orgData } = this.state;
    console.log("noor", consumerData);
    let orgTag = orgData.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    let listOrg =
      orgData.length > 0 &&
      orgData.map((items, i) => {
        return (
          <option key={items.org_id} value={items.org_id}>
            {items.name}
          </option>
        );
      }, this);

    if (consumerData) {
      let consumerTag = consumerData.sort(function (a, b) {
        return a.first_name.localeCompare(b.first_name);
      });
    }

    const onFinish = (values) => {
      console.log("america");
      let body = {
        org_id: this.state.formData.org_id,
      };
      ApiService.adddetails.consumerList(body).then((data) => {
        console.log(data, "res");
        this.setState({ isFetchSpeciality: true, isLoading: true });
        if (data.status === 200) {
          this.setState({ adminFullyLoaded: true, consumerData: data.data });
        }
      });
      // .catch(error => {
      //     this.setState({ isFetchSpeciality: false })
      // });
    };

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    return (
      <>
        <Row>
          <Col span={8} className="flexcenter">
            <h3 className="heading-font">SUBMITTER CONSUMER</h3>
          </Col>
        </Row>
        {/* <Col span={16}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <ul className="closecodeheaderMenu">
                        <li className="bellNotification">
                            <BellFilled style={{ color: '#0252a1' }} />
                        </li>
                        <li className="profile_button">

                            <div className="headerPorifileDiv">
                                <Avatar shape="square" size="large" icon={<UserOutlined />} style={{ borderRadius: '30%' }} />
                                <span className="proifle_name">
                                    Welcome Sally Rivera
                                </span>
                            </div>
                        </li>
                        <li className="logoutbutton">
                            <Button className="buttonOutline" ghost size="default" onClick={() => this.signOut()}>
                                Logout
                            </Button>
                        </li>
                    </ul>
                </div>
            </Col> */}

        <Row>
          <div className="sub-bar-other">
            {sessionStorage.getItem("rolename") === "Super Admin" && (
              <Search
                placeholder="Search Consumer"
                onSearch={(value) => console.log(value)}
                onKeyUp={(value) => {
                  console.log("tire2", this.state.formData.org_id);
                  ApiService.general
                    .search_user({
                      user_type: "consumer",
                      key: value.target.value,
                      org_id: this.state.formData.org_id,
                    })
                    .then((res) => {
                      console.log(res);
                      if (res.data === null && value.target.value === "") {
                        this.props.history.push("/consumer-list");
                      } else {
                        this.setState({
                          consumerData: res.data,
                          isLoading: true,
                        });
                        this.Tablecolumns();
                      }
                      // this.setView(res.data)
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              />
            )}
            {sessionStorage.getItem("rolename") === "Admin" && (
              <Search
                placeholder="Search consumer"
                onSearch={(value) => console.log(value)}
                onKeyUp={(value) => {
                  // console.log(value.target.value)
                  ApiService.general
                    .search_user({
                      user_type: "consumer",
                      key: value.target.value,
                      org_id: this.state.formData.org_id,
                    })
                    .then((res) => {
                      console.log(res);
                      if (res.data === null && value.target.value === "") {
                        this.props.history.push("/consumer-list");
                      } else {
                        this.setState({
                          consumerData: res.data,
                          isLoading: true,
                        });
                        this.Tablecolumns();
                      }
                      // this.setView(res.data)
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              />
            )}
            {sessionStorage.getItem("rolename") === "Super Admin" && (
              <Link to={{ pathname: "/add-consumer" }}>
                <Button className="addConsumerButton" id="" type="primary">
                  Add Consumer
                </Button>
              </Link>
            )}
            {sessionStorage.getItem("rolename") === "Admin" && (
              <Link to={{ pathname: "/add-consumer" }}>
                <Button className="addConsumerButton" id="" type="primary">
                  Add Consumer
                </Button>
              </Link>
            )}

            <ReactFileReader handleFiles={this.handleFiles} fileTypes={".csv"}>
              <Button className="addConsumerButton" type="primary">
                Bulk Upload
              </Button>
            </ReactFileReader>

            <a href={require("../../../assets/files/consumers.csv")}>
              <Button
                className="addConsumerButton"
                type="primary"
                id=""
                onClick={() => this.showMessage()}
              >
                Template
              </Button>
            </a>
          </div>
          {/* <button type="primary" className="add-btn">Add Close Code</button>*/}
        </Row>
        {/* <Row>
          <div className="sub-bar" style={{ marginTop: "-25px" }}>
            {sessionStorage.getItem("rolename") === "Super Admin" && (
              <Search
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
                placeholder="Search consumer"
                onSearch={(value) => console.log(value)}
                onKeyUp={(value) => {
                  // console.log(value.target.value)
                  ApiService.general
                    .search_user({
                      user_type: "consumer",
                      key: value.target.value,
                      org_id: this.state.formData.org_id,
                    })
                    .then((res) => {
                      console.log(res);
                      if (res.data === null && value.target.value === "") {
                        this.props.history.push("/consumer-list");
                      } else {
                        this.setState({
                          consumerData: res.data,
                          isLoading: true,
                        });
                        this.Tablecolumns();
                      }
                      // this.setView(res.data)
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              />
            )}
            {sessionStorage.getItem("rolename") === "Admin" && (
              <Search
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
                placeholder="Search consumer"
                onSearch={(value) => console.log(value)}
                onKeyUp={(value) => {
                  // console.log(value.target.value)
                  ApiService.general
                    .search_user({
                      user_type: "consumer",
                      key: value.target.value,
                      org_id: sessionStorage.getItem("org_id"),
                    })
                    .then((res) => {
                      console.log(res);
                      if (res.data === null && value.target.value === "") {
                        this.props.history.push("/consumer-list");
                      } else {
                        this.setState({
                          consumerData: res.data,
                          isLoading: true,
                        });
                        this.Tablecolumns();
                      }
                      // this.setView(res.data)
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              />
            )}
          </div>
        </Row> */}
        <div>
          <Row>
            <Col span={24}>
              <div>
                <Table
                  columns={this.Tablecolumns()}
                  //rowKey={record => record.id} not my
                  dataSource={this.state.consumerData}
                  pagination={this.state.pagination}
                  // loading={!this.state.isLoading}
                  //  onChange={this.handleTableChange}
                />
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
