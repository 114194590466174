import React, { Component, useState } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Layout,
  message,
  Form,
  Upload,
  Select,
  Checkbox,
  Tooltip,
  Popover,
  Modal,
  List,
  notification,
  Radio,
  DatePicker,
  Spin,
  Avatar,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { Container } from "reactstrap";
import { Link, NavLink, Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import "../Admin/admin.css";
import moment from "moment";
import ApiService from "../../services/api-services";
import firebase from "../../services/firebase";
import { CONFIG, DOMAIN_NAME } from "../../services/config";
import UserContext from "../../UserContext";
const FormItem = Form.Item;
const { Option } = Select;
//const dateFormat = "MM-DD-YYYY";
const dateFormat = ["MM-DD-YYYY", "MM/DD/YYYY", "MMDDYYYY"];
const items = [];

/*Profile Upload comp-----Startss */
const ProfileUpload = ({ image_url, userId }) => {
  const filetypes = ["image/jpeg", "image/png", "image/gif"];
  const [load, setload] = useState(null);
  const [imagePreview, setimagePreview] = useState(image_url);
  const [active, setactive] = useState("edit");

  /* Editing Profile pictuew--Starts */
  const ImgUpload = ({ onChange, src }) => {
    console.log(image_url, "url");
    return (
      <label htmlFor="photo-upload" className="custom-file-upload fas">
        {src ? (
          <Spin spinning={load}>
            <img className="image-style" src={src} />
          </Spin>
        ) : (
          <Avatar
            shape="square"
            size="large"
            icon={<UserOutlined />}
            style={{
              borderRadius: "10%",
              position: "relative",
              height: 100,
              width: 100,
              padding: 23,
            }}
          />
        )}
        <input id="photo-upload" type="file" onChange={onChange} />
      </label>
    );
  };
  function SetUserprofileLocally(url) {
    sessionStorage.setItem("staffResponderProfile", url);
    message.success("Image uploaded Successfully");
  }
  function uploadImage(url, userId, file) {
    const imgUrl = URL.createObjectURL(file);
    fetch(imgUrl)
      .then((r) => r.blob())
      .then((res) => {
        var metadata = {
          contentType: "image/jpeg",
          cacheControl: "public,max-age=300",
        };

        const ref = firebase.storage().ref("/profile_url/" + userId);
        console.log(userId, "ref");
        const uploadTask = ref.put(res, metadata);

        const handleState = (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              setload(true);
              console.log("Upload is running");
              break;
          }
        };
        const handleError = (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
            // User canceled the upload
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        };
        const handleSuccess = () => {
          setload(false);
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log(downloadURL, "downloadURL");
            SetUserprofileLocally(downloadURL);
          });
        };
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          handleState,
          handleError,
          handleSuccess
        );
      });
  }
  const photoUpload = (e) => {
    console.log(e, "upload");
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (!filetypes.includes(file.type)) {
      message.error("Upload valid image only png and jpeg are allowed");
      return;
    }

    if (file.size >= 1000000) {
      alert("file size is too large");
      return;
    }

    console.log(file, "src");
    reader.onloadend = () => {
      uploadImage(reader.result, userId, file);
      setimagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const Edit = ({ onSubmit, children }) => {
    return (
      <div className="card">
        <form onSubmit={onSubmit}>
          <div className="profile-card">
            <h1>User Profile</h1>
            {children}
          </div>
        </form>
      </div>
    );
  };
  /* Editing Profile pictuew--Endss */

  const handleSubmit = (e) => {
    e.preventDefault();
    let activeP = active === "edit" ? "profile" : "edit";
    setactive(activeP);
  };

  return (
    <div>
      <Edit onSubmit={handleSubmit}>
        <ImgUpload onChange={photoUpload} src={imagePreview} />
      </Edit>
    </div>
  );
};
/*Profile Upload comp-----Endss */

export default class AddAdmin extends Component {
  static contextType = UserContext;

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  constructor(props) {
    super(props);
    var org_id,
      type,
      formData = {};
    if (this.props.history.location.state !== undefined) {
      formData = {
        user_id: this.props.history.location.state.user_id,
        first_name: this.props.history.location.state.first_name,
        last_name: this.props.history.location.state.last_name,
        email: this.props.history.location.state.email,
        password: this.props.history.location.state.password,
        org_id: this.props.history.location.state.org_id,
        organization: this.props.history.location.state.organization,
        dob: this.props.history.location.state.dob,
        role: this.props.history.location.state.role,
        //role_id: this.props.history.location.state.role_id,
        mobile: this.props.history.location.state.mobile,
        experience: this.props.history.location.state.experience,
        hospital: this.props.history.location.state.hospital,
        bio: this.props.history.location.state.bio,
        image_url: this.props.history.location.state.image_url,
        type: this.props.history.location.state.type,
      };

      //  this.formType = this.props.history.location.state.type
    } else {
      formData = {
        type: "add",
      };
    }
    this.state = {
      token: sessionStorage.getItem("token"),
      formData: formData,
      Orglist: [],
      rolelist: [],
      buttonName: "",
      formType: type,
    };
  }

  componentDidMount() {
    const { formData } = this.state;
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }
    if (isLoggedIn === "false") {
      this.props.history.push("/login");
    }

    this.getOrganization();
    this.getRoles();

    if (this.state.formData.type === "edit") {
      this.setState({ buttonName: "Update" });
    } else if (this.state.formData.type === "delete") {
      this.setState({ buttonName: "Delete", isDisabled: true });
    } else {
      this.setState({
        formData: { ...formData, dob: "" },
      });
      this.setState({ buttonName: "Save" });
    }

    message.config({ top: 150, duration: 2 });
    sessionStorage.removeItem("staffResponderProfile");
  }

  validateForm() {
    // alert('asda')
    const data = this.state.formData;
    console.log("f", data);
    var regexp = new RegExp(/^[a-zA-Z0-9 ]+$/);

    if (data === "") {
      // message.warn("Please fill the details");
    } else {
      if (data.name === "") {
        message.warn("Please fill the details");
      } else {
        if (this.state.buttonName === "Update") {
          this.updateSave();
        } else if (this.state.buttonName === "Save") {
          //alert('save')
          this.doSave(); //change this
        } else {
          // this.deleteCompany();
        }
      }
    }
  }

  getOrganization() {
    ApiService.adddetails.getOrgList().then((data) => {
      if (data.status === 200) {
        const newData = data.data.filter((item) => {
          if (item.name && item.org_id) {
            if (item.status !== 0) {
              return item;
            }
          }
        });
        this.setState({ Orglist: newData });
      }
    });
  }

  getRoles() {
    ApiService.adddetails.getRoleType().then((data) => {
      if (data.status === 200) {
        //  console.log("tagOrg",data)
        let roleData = data.data.response.filter(
          (item) => item.role_name === "Responder Staff"
        );
        this.setState({ rolelist: roleData });
      }
    });
  }

  handleChange = (e, field) => {
    console.log(e);
    const { formData } = this.state;

    if (field === "first_name") {
      console.log(e);
      this.setState({
        formData: { ...formData, [field]: e },
      });
      return;
    } else if (field === "last_name") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "email") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "password") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "org_id") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "role_id") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "dob") {
      e = moment(e).format("MM-DD-YYYY");
      // console.log(e,'111')
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "mobile") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "experience") {
      let experience = {};
      const value = e;
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "") {
        experience = { ...formData, [field]: value };
      }
      this.setState(
        {
          formData: experience,
        },
        function () {}
      );
    } else if (field === "about") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "hospital_name") {
      console.log(e);

      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else {
      this.setState(
        {
          formData: { ...formData, [field]: e.target.value },
        },
        function () {}
      );
    }
  };

  doSave() {
    let body = {
      first_name:
        this.state.formData.first_name.charAt(0).toUpperCase() +
        this.state.formData.first_name.slice(1),
      last_name:
        this.state.formData.last_name.charAt(0).toUpperCase() +
        this.state.formData.last_name.slice(1),
      dob: this.state.formData.dob,
      email: this.state.formData.email,
      password: this.state.formData.password,
      org_id: this.state.formData.org_id,
      role_id: this.state.formData.role_id,
      bio: this.state.formData.about,
      experience: parseInt(this.state.formData.experience),
      hospital_name: this.state.formData.hospital_name,
      image_url: sessionStorage.getItem("staffResponderProfile")
        ? sessionStorage.getItem("staffResponderProfile")
        : "",
    };
    if (this.state.formData.mobile) {
      body.mobile =
        DOMAIN_NAME === "https://staging.afya.chat"
          ? "+91" + this.state.formData.mobile
          : "+1" + this.state.formData.mobile;
    }
    console.log(body, "111");
    ApiService.adddetails
      .add_staff_res(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          sessionStorage.removeItem("staffResponderProfile");
          message.success("Successfully added the staff");
          this.props.history.push("/StaffResponder");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.status === 702) {
          message.error("Email ID already exist");
        }
      });
  }

  updateSave() {
    this.setState({ isLoading: true });

    let body = {
      user_id: this.state.formData.user_id,
      first_name:
        this.state.formData.first_name.charAt(0).toUpperCase() +
        this.state.formData.first_name.slice(1),
      last_name:
        this.state.formData.last_name.charAt(0).toUpperCase() +
        this.state.formData.last_name.slice(1),
      dob: this.state.formData.dob === null ? "" : this.state.formData.dob,
      email: this.state.formData.email,
      //"password": this.state.formData.password,
      // org_id: this.state.formData.org_id,
      role_id: "6ecd8c99-4036-403d-bf84-cf8400f67769",
      bio: this.state.formData.about,
      experience: parseInt(this.state.formData.experience),
      mobile:
        DOMAIN_NAME === "https://staging.afya.chat" &&
        this.state.formData.mobile !== null
          ? "+91" + this.state.formData.mobile
          : "+1" + this.state.formData.mobile,
      hospital_name: this.state.formData.hospital_name,
      image_url: sessionStorage.getItem("staffResponderProfile")
        ? sessionStorage.getItem("staffResponderProfile")
        : this.state.formData.image_url,
    };

    ApiService.adddetails
      .editStaffResponder(body)
      .then((data) => {
        // alert("edit api")
        // console.log(data,'444')
        this.setState({ isLoading: false });
        if (data.status === 200) {
          sessionStorage.removeItem("staffResponderProfile");
          message.success("successfully updated the Staff");
          this.props.history.push("/StaffResponder");
        } else if (data.statusCode === 500) {
          message.error("Company already exist");
        } else if (data.statusCode === 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.statusCode === 500) {
          message.error("Company already exist");
        }
      });
  }

  render() {
    const { Orglist, rolelist, formData } = this.state;
    console.log(this.state.formData, "formdata1");
    let orgTag = Orglist.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    let roleTag = rolelist.sort(function (a, b) {
      return a.role_name.localeCompare(b.role_name);
    });

    let listOrg =
      orgTag.length > 0 &&
      orgTag.map((items, i) => {
        return (
          <option key={items.org_id} value={items.org_id}>
            {items.name}
          </option>
        );
      }, this);

    let listRole =
      roleTag.length > 0 &&
      roleTag.map((items, i) => {
        return (
          <option key={items.role_id} value={items.role_id}>
            {items.role_name}
          </option>
        );
      }, this);

    let disabledDate = (current) => {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    };

    return (
      <Container>
        <div className="row-1">
          <h3 className="heading-font">
            {this.state.formData.type === "edit"
              ? "Edit Responder Staff"
              : "Add Responder Staff"}
          </h3>
          <ProfileUpload
            image_url={this.state.formData.image_url}
            userId={this.state.formData.user_id}
          />
        </div>
        <Form
          autoComplete="off"
          onFinish={() => this.validateForm()}
          initialValues={{
            first_name: this.state.formData.first_name,
            last_name: this.state.formData.last_name,
            dob: this.state.formData.dob ? moment(this.state.formData.dob) : "",
            email: this.state.formData.email,
            password: this.state.formData.password,
            Organization: this.state.formData.organization,
            Role: this.state.formData.role,
            Mobile_Number: this.state.formData.mobile
              ? this.state.formData.mobile.substr(
                  this.state.formData.mobile.length - 10
                )
              : "",
            Experience_In_Year: this.state.formData.experience,
            Hospital_Name: this.state.formData.hospital,
            About_Doctor: this.state.formData.bio,
          }}
        >
          <FormItem
            label="First Name"
            name="first_name"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter a Name",
              },
              {
                pattern: /^[a-zA-Z -]*$/,
                message: "Please enter a valid Name",
              },
            ]}
          >
            <Input
              className="text-input"
              id=""
              placeholder="First Name"
              onChange={(e) => this.handleChange(e.target.value, "first_name")}
              //onChange={() => this.onFinish()}
            />
          </FormItem>

          <FormItem
            label="Last Name"
            name="last_name"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter a Name",
              },
              {
                pattern: /^[a-zA-Z -]*$/,
                message: "Please enter a valid Name",
              },
            ]}
          >
            <Input
              className="text-input"
              id=""
              placeholder="Last Name"
              onChange={(e) => this.handleChange(e.target.value, "last_name")}
              //onChange={() => this.onFinish()}
            />
          </FormItem>
          <Form.Item
            label="DOB"
            name="dob"
            className="display"
            rules={[
              {
                required: false,
                message: "Please enter a DOB",
              },
            ]}
          >
            <DatePicker
              className="text-input"
              placeholder="Select or enter date in 'MM-DD-YYYY'"
              defaultValue={moment("", dateFormat)}
              disabledDate={disabledDate}
              format={dateFormat}
              value={this.state.formData.dob}
              onChange={(e) => this.handleChange(e, "dob")}
            />
          </Form.Item>
          {/* {this.state.formData.type != "edit" && ( */}
          <Form.Item
            label="Email Id"
            name="email"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter email",
              },
              {
                // pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                //pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]+.[a-zA-Z0-9-]+$/,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a email id",
              },
            ]}
          >
            <Input
              className="text-input"
              id=""
              placeholder="Email Id"
              onChange={(e) => this.handleChange(e.target.value, "email")}
            />
          </Form.Item>
          {/* )} */}

          {this.state.formData.type != "edit" && (
            <Form.Item
              label="Password"
              name="password"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter the password",
                },
                {
                  // pattern: /^[A-Za-z]\w{7,14}$/,
                  // pattern:
                  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                  //above pattern is commented so that henceforth it can include all the special chars.
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};:'",<>\./?\\|`~])(?=.{8,})/,
                  message:
                    "Please enter a strong password (min 6 characters which contain special characters,characters, numeric digits  )",
                },
              ]}
            >
              <Input.Password
                className="text-input"
                placeholder="Password"
                onChange={(e) => this.handleChange(e.target.value, "password")}
              />
            </Form.Item>
          )}
          {/* {sessionStorage.getItem("rolename") === "Super Admin" && ( */}
          {/* {this.state.formData.type != "edit" && (
            <Form.Item
              label="Organization"
              name="Organization"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please select Organization",
                },
              ]}
            >
              <Select
                showSearch
                className="text-select"
                //labelInValue
                placeholder="Select a Organization"
                optionFilterProp="children"
                onChange={(e) => this.handleChange(e, "org_id")}
              >
                {listOrg}
              </Select>
            </Form.Item>
          )} */}

          {/* )} */}
          {this.state.formData.type != "edit" && (
            <Form.Item
              label="Roles"
              name="Role"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please select Role",
                },
              ]}
            >
              <Select
                showSearch
                className="text-select"
                //mode="tags"
                placeholder="Please select Role"
                optionFilterProp="children"
                onChange={(e) => this.handleChange(e, "role_id")}
              >
                {listRole}
              </Select>
            </Form.Item>
          )}

          <FormItem
            name="Mobile_Number"
            label="Mobile"
            className="display"
            rules={[
              // {
              //   required: true,
              //   message: "Please enter Mobile number",
              // },
              {
                pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,

                message: "Please enter a valid Phone number",
              },
            ]}
          >
            <Input
              className="text-input"
              placeholder="Mobile Number"
              value={this.state.formData.mobile}
              onChange={(e) => this.handleChange(e.target.value, "mobile")}
            />
          </FormItem>

          <FormItem
            name="Experience_In_Year"
            label="Experience"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter your Experience",
              },
            ]}
          >
            <Input
              className="text-input"
              id=""
              placeholder="Experience in year"
              type="number"
              step="1"
              min="1"
              value={this.state.formData.experience}
              onChange={(e) => this.handleChange(e.target.value, "experience")}
            />
          </FormItem>
          <FormItem
            name="Hospital_Name"
            label="Hospital"
            className="display"
            rules={[
              {
                required: false,
                message: "Please enter Hospital Name",
              },
            ]}
          >
            <Input
              className="text-input"
              placeholder="Hospital Name"
              value={this.state.formData.hospital_name}
              onChange={(e) =>
                this.handleChange(e.target.value, "hospital_name")
              }
            />
          </FormItem>
          <FormItem
            name="About_Doctor"
            label="Bio"
            className="display"
            rules={[
              {
                required: true,
                message: "Please enter Bio",
              },
            ]}
          >
            <TextArea
              placeholder="About Doctor"
              value={this.state.formData.about}
              onChange={(e) => this.handleChange(e.target.value, "about")}
            ></TextArea>
          </FormItem>

          <Row className="button-padding">
            {this.state.buttonName !== "" && (
              <Button
                className="btn-type"
                id=""
                loading={this.state.isLoading}
                type="primary"
                htmlType="submit"
              >
                {this.state.buttonName}
              </Button>
            )}
            <Link to={{ pathname: "/StaffResponder" }}>
              <Button className="btn-type" id="" type="primary">
                Cancel
              </Button>
            </Link>
          </Row>
        </Form>
      </Container>
    );
  }
}
