import React from "react";

import { NavLink } from "react-router-dom";
import { Menu, message } from "antd";
import "./Menus.css";
const { SubMenu } = Menu;

const menudata = [
  {
    title: "DASHBOARD",
    nav: "/Dashboard",
    roles: ["Super Admin", "Admin", "Session Admin"],
  },
  {
    title: "MASTER",
    roles: ["Super Admin"],
    subItems: [
      {
        title: "CLOSE_CODE",
        nav: "/CloseCode",
      },
      {
        nav: "/ParticipantPermission",
        title: "PARTICIPANT_PERMISSION",
      },
      {
        title: "SPECIALTY",
        nav: "/speciality",
      },
      {
        title: "TIME FRAMES",
        nav: "/TimeFrame",
      },
      {
        title: "AFYA PLAN",
        nav: "/AfyaPlan",
      },
      {
        title: "PROMO CODES",
        nav: "/PromoCode",
      },
      {
        title: "SUB GROUPS",
        nav: "/SubGroups",
      },
      {
        title: "RESPONDER-LINKING",
        nav: "/Responder",
      },
      {
        title: "PASSWORD",
        nav: "/Password",
      },
      {
        title: "In-App Notification",
        nav: "/in-app-notification",
      },
      {
        title: "Configure Message",
        nav: "/configure-messages",
      },
      {
        title: "Ai Access",
        nav: "/ai-access",
      },
      {
        title: "User Notification Settings",
        nav: "/user-notification-settings",
      },
    ],
  },
  {
    title: "ADMIN MANAGEMENT",
    nav: "/admin-list",
    roles: ["Super Admin"],
  },
  {
    title: "ALL USERS",
    nav: "/user-details",
    roles: ["Super Admin"],
  },
  {
    title: "AFYA PROMPTS",
    nav: "/afyaPrompts",
    roles: ["Super Admin"],
  },
  {
    title: "USER",
    nav: "/user-list",
    roles: ["Super Admin", "Admin"],
  },
  {
    title: "STAFF-SUBMITTER",
    nav: "/add-staff",
    roles: ["Super Admin", "Admin"],
  },
  {
    title: "STAFF-RESPONDER",
    nav: "/StaffResponder",
    roles: ["Super Admin"],
  },
  {
    title: "SUBMITTER CONSUMER",
    nav: "/consumer-list",
    roles: ["Super Admin", "Admin"],
  },
  sessionStorage.getItem("rolename") === "Super Admin" && {
    title: "ORGANIZATION",
    nav: "/organization-list",
    roles: ["Super Admin", "Admin"],
  },

  sessionStorage.getItem("rolename") === "Admin" && {
    title: "ORGANIZATION",
    nav: "/add-orgn",
    roles: ["Super Admin", "Admin"],
  },

  {
    title: "CHATS",
    nav: "/chat-list",
    roles: ["Super Admin", "Admin", "Session Admin"],
  },
  // {
  //   title: "PATIENT REPLIES",
  //   nav: "/PatientReplies",
  //   roles: ["Super Admin"],
  // },
];

const Menus = (props) => {
  const { role } = props;
  if (props.roles && props.roles.includes(role)) {
    if (Array.isArray(props.subItems)) {
      return (
        <SubMenu icon={props.icon} title={props.title} {...props}>
          {props.subItems.map((item) => (
            <Menu.Item key={item.title} icon={props.icon} {...item}>
              <NavLink
                to={item.nav}
                activeClassName="menu-is-active"
                id="font-colour4"
              >
                {" "}
                {item.title}
              </NavLink>
            </Menu.Item>
          ))}
        </SubMenu>
      );
    }

    return (
      <Menu.Item key={props.title} icon={props.icon} {...props}>
        <NavLink
          to={props.nav}
          activeClassName="menu-is-active"
          id="font-colour4"
        >
          {" "}
          {props.title}
        </NavLink>
      </Menu.Item>
    );
  }
  return null;
};

export default ({ roles }) => {
  let role = roles;
  if (!role) {
    role = sessionStorage.getItem("rolename");
  }

  return (
    <Menu
      theme={{
        theme: "dark",
        current: "1",
      }}
      style={{ width: 256 }}
      defaultOpenKeys={["sub1"]}
      selectedKeys={[0]}
      mode="inline"
    >
      {menudata.map((item) => {
        return <Menus {...item} role={role} key={item.title} />;
      })}
    </Menu>
  );
};
