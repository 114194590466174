module.exports = {


    // DOMAIN_NAME: 'https://staging.afya.chat',
    // ADMIN_DOMAIN_NAME: 'https://staging.afya.chat',
    // DOMAIN_NAME: 'https://backend.afya.chat',
    // ADMIN_DOMAIN_NAME: 'https://backend.afya.chat',
     DOMAIN_NAME: 'https://uat.afya.chat',
     ADMIN_DOMAIN_NAME: 'https://uat.afya.chat',
    // IMAGE_PATH: "http://13.124.91.53/static/media/images/",
    // PROFILE_PATH: "http://13.124.91.53/static/media/images/profilepic/",
    // NEWS_IMAGE_PATH: "http://13.124.91.53/static/media/images/breakingnewsimages/",    
};
