import React, { Component, } from 'react';
import {
    Row, Col

} from 'antd';
// import {
//     UserOutlined, SearchOutlined,
//     SortAscendingOutlined, SlidersOutlined, BellFilled,
//     AlertFilled, CloudDownloadOutlined
// } from '@ant-design/icons';
import 'antd/dist/antd.css';
import './dashboard.css'
import UserContext from '../../../UserContext';
//import DoctorImage from '../../../assets/img/Doctor.png'
//import rating from '../../../assets/img/star.png'
import Users from '../../../assets/img/total.png'
//import Patient from '../../../assets/img/patient.png'
import Doctor from '../../../assets/img/doctors.png'
import Earning from '../../../assets/img/earning.png'


export default class dashboard extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props);
        this.token = sessionStorage.getItem('token')
        this.loggedIn = sessionStorage.getItem('isLoggedIn')
        this.state = {
          loggedId: sessionStorage.getItem('isLoggedIn'),
          token: sessionStorage.getItem('token'),
          isLoading: false
        }
    }
    
    // signOut() {
    //     const { setLoggin } = this.context
    //     setLoggin('false');
    //     this.props.history.push('/login')
    //     sessionStorage.setItem('isLoggedIn', false);
    // }
    componentDidMount() {
       // alert(this.state.token)
        if(this.state.token === null || this.state.token === '') {
            this.props.history.push('/login')
            window.location.reload(true)
        }
    }
    
    render()
    {
        return (
            <>
             <Row>
            <Col span={8} className="flexcenter">
                <h3 className='heading-font'>DASHBOARD</h3>
            </Col>
            {/* <Col span={16}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    
                }}>
                    <ul className="dashboardheaderMenu">
                        <li className="bellNotification">
                            <BellFilled style={{ color: '#0252a1' }} />
                        </li>
                        <li className="profile_button">

                            <div className="headerPorifileDiv">
                                <Avatar shape="square" size="large" icon={<UserOutlined />} style={{ borderRadius: '30%' }} />
                                <span className="proifle_name">
                                    Welcome Sally Rivera
                                </span>
                            </div>
                        </li>
                        <li className="logoutbutton">
                            <Button className="buttonOutline" ghost size="default" onClick={() => this.signOut()}>
                                Logout
                            </Button>
                        </li>
                    </ul>
                </div>
            </Col> */}
            </Row> 
            <Row>
                <Col>
                <div className="head">
                    <div className="Heading">
                        <div className="total">
                            <span>
                                Total Users
                            </span>
                        </div>
                        <div>
                            <span className="Users">
                                2,00,000
                            </span>
                            <span>
                                <img className="user" src={Users}></img>
                            </span>
                        </div>
                    </div>
                    
                    <div className="Heading doctor">
                        <div className="total">
                        <span>
                            Doctors
                        </span>
                        </div>
                    <div>
                        <span className="Users">
                           25,000
                        </span>
                        <span>
                            <img className="user" src={Doctor}></img>
                        </span>
                    </div>
                    </div>
                    <div className="Heading earning">
                        <div className="total">
                        <span>
                            Earnings
                        </span>
                        </div>
                    <div>
                        <span className="Users">
                            $ 10,483
                        </span>
                        <span>
                            <img className="user" src={Earning}></img>
                        </span>
                    </div>
                    </div>
                </div>
                </Col>
            </Row>
            </>
        );
    }
}
