import React, { useState } from "react";
import { Button, Row, Input } from "antd";
import ListStaff from "./ViewStaff";
import Staff from "./AddStaff";
import "./index.css";

export default () => {
  const [initialFormLoad, setinitialFormLoad] = useState(false);
  const [addStaff, setaddStaff] = useState(null);
  const [editStaff, seteditStaff] = useState(null);
  const [OrgId, setOrgId] = useState(null);
  const [staffData, setStaffdata] = useState(null);

  const handleEdit = (props) => {
    if (typeof props === "object") {
      if (!props["org_details"]) {
        props.org_details = [
          {
            org_id: OrgId,
            roles: [""],
          },
        ];
      }
      setStaffdata(props);
      seteditStaff(true);
    }
  };

  const showDeleteConfirm = () => {};

  return initialFormLoad ? (
    <>loading </>
  ) : (
    <div className="body-color wrapper">
      {addStaff ? (
        <Staff cancel={(e) => setaddStaff(false)} />
      ) : editStaff ? (
        <Staff
          data={staffData}
          type="edit"
          cancel={(e) => seteditStaff(false)}
        />
      ) : (
        <>
          <Button
            className="btn-type new_button"
            style={{ marginTop: 54 }}
            type="primary"
            onClick={(e) => {
              setaddStaff(true);
            }}
          >
            Add Staff
          </Button>
          <ListStaff
            handleEdit={handleEdit}
            showDeleteConfirm={showDeleteConfirm}
            saveOrgId={(id) => {
              console.log(id, "orgids");
              setOrgId(id);
            }}
            org_id={OrgId}
          />
        </>
      )}
    </div>
  );
};
