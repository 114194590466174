import requestServer from "../request-server";
import CONFIG from "../config";

var internals = {};
/*Add Organization */
internals.addOrg = function (data) {
  return requestServer(
    "/super_admin/add_org",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

/*Add In app notification */
internals.addNotification = function (data) {
  return requestServer(
    "/super_admin/admin_notification_schedule",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addTwilioMessageConfiguration = function (data) {
  return requestServer(
    "/super_admin/org_twil_msg_config",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.getOrgType = function () {
  return requestServer(
    "/super_admin/org_type_list",
    null,
    "GET",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

/*Add Doctors */
internals.addDoctor = function (data) {
  return requestServer(
    "/super_admin/add_provider",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};
internals.editDoctor = function (data) {
  return requestServer(
    "/super_admin/edit_provider",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.editSpec = function (data) {
  return requestServer(
    "/super_admin/edit_Speciality",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.searchDoc = function (data) {
  return requestServer(
    "/super_admin/search_doc",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.getSplType = function (data) {
  return requestServer(
    "/super_admin/speciality_master",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.getOrgSplType = function (data) {
  return requestServer(
    "/super_admin/view_org_spec",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.viewOrg = function (data) {
  return requestServer(
    "/super_admin/view_org",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.getRoleType = function () {
  return requestServer(
    "/super_admin/role_list",
    null,
    "GET",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};
/*List doctors */
internals.getCompanies = function (data, pageNo, limit, searchKey) {
  //let data = { pageNo, limit, searchKey };
  return requestServer(
    "/super_admin/provider_list",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.deleteDoctor = function (data) {
  return requestServer(
    "/super_admin/delete_provider",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};
/*View doctors */
internals.viewDoctor = function (data) {
  return requestServer(
    "/super_admin/view_provider",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

/*List Organization */
internals.getOrgList = function (pageNo, limit, searchKey) {
  let data = { pageNo, limit, searchKey };
  return requestServer(
    "/super_admin/org_list",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};
internals.editOrg = function (data) {
  return requestServer(
    "/super_admin/edit_org",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};
internals.deleteOrg = function (data) {
  return requestServer(
    "/super_admin/delete_org",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};
internals.deleteDoc = function (data) {
  return requestServer(
    "/super_admin/delete_provider",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.deleteSpec = function (spec_id) {
  return requestServer(
    "/super_admin/delete_speciality",
    { spec_id },
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addSpeciality = function (data) {
  return requestServer(
    "/super_admin/add_speciality",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.createPlan = function (data) {
  return requestServer(
    "/super_admin/promo_plans",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.editPlan = function (data) {
  return requestServer(
    "/super_admin/edit_promo_plans",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.editclosecode = function (data) {
  return requestServer(
    "/super_admin/edit_close_code",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.edittimeframe = function (data) {
  return requestServer(
    "/super_admin/timeframe_operations",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.sessionDetails = function (data) {
  return requestServer(
    "/chat/session_details",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.subGroups = function (data) {
  return requestServer(
    "/super_admin/med_grp_ops",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addVideoCallLink = function (data) {
  return requestServer(
    "/chat/add_video_call_link",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addUserDetails = function (data) {
  return requestServer(
    "/super_admin/user_list_for_add_user",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addremoveSessionUser = function (data) {
  return requestServer(
    "/chat/add_remove_user_from_session",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.autoAssign = function (data) {
  return requestServer(
    "/user_management/auto_assign",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.manualAssign = function (data) {
  return requestServer(
    "/user_management/mannual_assign",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.Daildigest = function (data) {
  return requestServer(
    "/chat/daily_digest",
    data,
    "GET",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.closeCodes = function (data) {
  return requestServer(
    "/user_management/close_codes",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.userLoginDetails = function (data) {
  return requestServer(
    "/user_management/user_login_details",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.timeFrames = function (data) {
  return requestServer(
    "/chat/master_timeframes_list",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.qAndA = function (data) {
  return requestServer(
    "/super_admin/q_a_operations",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.permission = function (data) {
  return requestServer(
    "/user_management/permissions",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addAdmin = function (data) {
  return requestServer(
    "/super_admin/add_admin",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.adminlist = function (data) {
  return requestServer(
    "/super_admin/admin_list",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.planlist = function (data) {
  return requestServer(
    "/super_admin/promo_plans_list",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.editAdmin = function (data) {
  return requestServer(
    "/super_admin/edit_admin",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.atStaff = function (data) {
  return requestServer(
    "/super_admin/at_staff",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.orgProvider = function (data) {
  return requestServer(
    "/super_admin/provider_by_org",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addPasswordLength = function (data) {
  return requestServer(
    "/super_admin/add_global_config",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addHelp = function (data) {
  return requestServer(
    "/super_admin/add_org_help_maps",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addclosecode = function (data) {
  return requestServer(
    "/super_admin/add_close_code",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addpermission = function (data) {
  return requestServer(
    "/super_admin/add_permission",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.adduser = function (data) {
  return requestServer(
    "/super_admin/bulk_users",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.addPatient = function (data) {
  return requestServer(
    "/user_management/add_patients",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.consumerList = function (data) {
  return requestServer(
    "/user_management/consumer_list",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

/*internals.closeCodes = function () {
  return requestServer('/user_management/close_codes', null, 'GET', {}, null, null, false, CONFIG.DOMAIN_NAME);
}*/

internals.staffProvider = function (data) {
  return requestServer(
    "/chat/org_submitter_provider",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.add_staff = (data) => {
  return requestServer(
    "/super_admin/add_staff",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.add_bulk_consumer = function (data) {
  return requestServer(
    "/user_management/patient_bulk_upload",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.promolist = function (data) {
  return requestServer(
    "/super_admin/promo_codes_list",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.createPromo = function (data) {
  return requestServer(
    "/super_admin/promo_codes",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.planlist = function (data) {
  return requestServer(
    "/super_admin/promo_plans_list",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.generatePromo = function (data) {
  return requestServer(
    "/super_admin/gen_promo_code",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.add_staff_res = (data) => {
  return requestServer(
    "/super_admin/create_responder_staff",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.staffResList = (data) => {
  return requestServer(
    "/super_admin/responder_staff_list",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.responderList = function (data) {
  return requestServer(
    "/super_admin/responder_provider_list",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.responder_staff_mapping = function (data) {
  return requestServer(
    "/super_admin/responder_staff_mapping",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.responder_staff_map_view = function (data) {
  return requestServer(
    "/super_admin/responder_staff_map_view",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.getPasswordLength = function () {
  return requestServer(
    "/super_admin/get_global_config_list",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.updatePasswordLength = function (data) {
  return requestServer(
    "/super_admin/update_global_config",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.masterInsurance = function () {
  return requestServer(
    "/user_management/master_insurances",
    {},
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.checkEmail = function (data) {
  return requestServer(
    "/user_management/check_email",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.editPatient = function (data) {
  return requestServer(
    "/user_management/edit_patients",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.forgotPassword = function (data) {
  return requestServer(
    "/user_management/forgot_password",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.editStaffResponder = function (data) {
  return requestServer(
    "/super_admin/edit_responder_staff",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.editSubmitterResponder = function (data) {
  return requestServer(
    "/super_admin/edit_staff",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

internals.activateUserOrg = function (data) {
  return requestServer(
    "/super_admin/activate_user_org",
    data,
    "POST",
    {},
    null,
    null,
    false,
    CONFIG.DOMAIN_NAME
  );
};

export default internals;
