import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";
import "firebase/analytics";
import CONFIG from "../services/config";

const firebaseConfig =
  CONFIG.DOMAIN_NAME === "https://staging.afya.chat"
    ? {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: "afya-staging.firebaseapp.com",
        databaseURL: "https://afya-staging.firebaseio.com",
        projectId: "afya-staging",
        storageBucket: "afya-staging.appspot.com",
        messagingSenderId: "878684835542",
        appId: "1:878684835542:web:9aea2943a425e6e70e0377",
        measurementId: "G-5ZYGC4H1C3",
      }
    : CONFIG.DOMAIN_NAME === "https://uat.afya.chat"
    ? {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: "afya-staging.firebaseapp.com",
        databaseURL: "https://afya-staging.firebaseio.com",
        projectId: "afya-staging",
        storageBucket: "afya-staging.appspot.com",
        messagingSenderId: "878684835542",
        appId: "1:878684835542:web:9aea2943a425e6e70e0377",
        measurementId: "G-5ZYGC4H1C3",
      }
    : {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: "afya-production.firebaseapp.com",
        databaseURL: "https://afya-production.firebaseio.com",
        projectId: "afya-production",
        storageBucket: "afya-production.appspot.com",
        messagingSenderId: "761816859088",
        appId: "1:761816859088:web:54577cbebb12b19d8c79d5",
        measurementId: "G-5YEFB4D2K5",
      };

firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase;
