import React, { Component, useState } from "react";

import {
  Row,
  Button,
  Input,
  message,
  Form,
  Select,
  DatePicker,
  Radio,
  Checkbox,
  Avatar,
  Spin,
  Switch,
  notification,
} from "antd";
import { Container } from "reactstrap";
import moment from "moment";
import "antd/dist/antd.css";
import "../Admin/admin.css";

//import { Link, NavLink } from 'react-router-dom';

import ApiService from "../../services/api-services";
import firebase from "../../services/firebase";
import { DOMAIN_NAME } from "../../services/config";
// import $, { $window } from "jquery";

// import { isLoggedIn } from '../../helpers/utility';

import TextArea from "antd/lib/input/TextArea";
//import { Redirect } from 'react-router-dom';
//import CONFIG from "../../services/config"
import UserContext from "../../UserContext";

//import AddOrg from '../../test'
import { PlusOutlined, MinusOutlined, UserOutlined } from "@ant-design/icons";

const FormItem = Form.Item;
//const dateFormat = "MM-DD-YYYY";
const dateFormat = ["MM-DD-YYYY", "MM/DD/YYYY", "MMDDYYYY"];

//for checking phone number
// const checkPhonenumber = () => {
//     const promise = Promise;
//     const val = form.getFieldValue('mobile')
//     let value = val

//     const rawPhone = value.slice(dailercode.length)
//     if (rawPhone && rawPhone.length === 10) {
//         return promise.resolve('')
//     }
//     if (rawPhone && rawPhone.length < 10) {
//         return promise.reject("Please enter a valid 10 digit number")
//     }

// }
const { Option } = Select;

/*Profile Upload comp-----Startss */
// sprint35
const ProfileUpload = ({ image_url, userId }) => {
  const filetypes = ["image/jpeg", "image/png", "image/gif"];
  const [load, setload] = useState(null);
  const [imagePreview, setimagePreview] = useState(image_url);
  const [active, setactive] = useState("edit");

  /* Editing Profile pictuew--Starts */
  const ImgUpload = ({ onChange, src }) => {
    console.log(image_url, "url");
    return (
      <label htmlFor="photo-upload" className="custom-file-upload fas">
        {src ? (
          <Spin spinning={load}>
            <img className="image-style-user-custom" src={src} />
          </Spin>
        ) : (
          <Avatar
            shape="circle" // Use shape "circle" for a circular avatar
            size="large"
            icon={<UserOutlined />}
            style={{
              borderRadius: "50%", // Set borderRadius to 50% to make it circular
              position: "relative",
              height: 80,
              width: 80,
              padding: 23,
            }}
          />
        )}
        <input id="photo-upload" type="file" onChange={onChange} />
      </label>
    );
  };
  function SetUserprofileLocally(url) {
    sessionStorage.setItem("userProfile", url);
    message.success("Image uploaded Successfully");
  }
  function uploadImage(url, userId, file) {
    const imgUrl = URL.createObjectURL(file);
    fetch(imgUrl)
      .then((r) => r.blob())
      .then((res) => {
        var metadata = {
          contentType: "image/jpeg",
          cacheControl: "public,max-age=300",
        };

        const ref = firebase.storage().ref("/profile_url/" + userId);
        console.log(ref, "ref");
        const uploadTask = ref.put(res, metadata);

        const handleState = (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              setload(true);
              console.log("Upload is running");
              break;
          }
        };
        const handleError = (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
            // User canceled the upload
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        };
        const handleSuccess = () => {
          setload(false);
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            console.log(downloadURL, "downloadURL");
            SetUserprofileLocally(downloadURL);
          });
        };
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          handleState,
          handleError,
          handleSuccess
        );
      });
  }
  const photoUpload = (e) => {
    console.log(e, "upload");
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (!filetypes.includes(file.type)) {
      message.error("Upload valid image only png and jpeg are allowed");
      return;
    }

    if (file.size >= 1000000) {
      alert("file size is too large");
      return;
    }

    console.log(file, "src");
    reader.onloadend = () => {
      uploadImage(reader.result, userId, file);
      setimagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const Edit = ({ onSubmit, children }) => {
    return (
      <div className="card">
        <form onSubmit={onSubmit}>
          <div className="profile-card">
            <h1>User Profile</h1>
            {children}
          </div>
        </form>
      </div>
    );
  };
  /* Editing Profile pictuew--Endss */

  const handleSubmit = (e) => {
    e.preventDefault();
    let activeP = active === "edit" ? "profile" : "edit";
    setactive(activeP);
  };

  return (
    <div>
      <Edit onSubmit={handleSubmit}>
        <ImgUpload onChange={photoUpload} src={imagePreview} />
      </Edit>
    </div>
  );
};
/*Profile Upload comp-----Endss */

export default class AddDoctor extends Component {
  static contextType = UserContext;

  formRef = React.createRef();
  formType = null;

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  constructor(props) {
    super(props);
    var user_id,
      type,
      formData = {};
    console.log(" add", props);

    if (this.props.location.state !== undefined) {
      formData = {
        user_id: this.props.location.state.user_id,
        type: this.props.location.state.type,
        dob: this.props.location.state.dob,
        image_url: this.props.location.image_url,
      };
      this.formType = this.props.location.state.type;
    } else {
      formData = {
        type: "add",
      };
      this.formType = "add";
    }
    console.log("wd", formData);

    this.state = {
      id: "",
      buttonName: "",
      org: "",
      imageUrl: "",
      formType: type,
      id: user_id,
      isLoading: false,
      resetLoading: false,
      isViewLoading: false,
      tagSpl: [],
      tagRole: [],
      tagOrg: [],
      toggleVal: 1,
      switchLoad: false,
      checkedStatus: true,
      checkedResponse: {},
      rolePrevord: {},
      rowLoader: false,
      plusButtonClick: false,
      getOrgVal: null,
      formData: formData,
      initialFormLoad: false,
      disp: false,
      subgroupData: [],
      display: false,
      userRole: "",
      token: sessionStorage.getItem("token"),
    };
  }

  getOrgDetails = () => {
    const data = this.state.formData.org_details;
    let d = data.map((item) => {
      if (item.org) {
        item.rol.map((item) => {
          if (item.key) {
            delete item["key"];
          }
          if (!item.role_id) {
            if (item.value) {
              item["role_id"] = item.value;
              delete item["value"];
            }
          }
          if (!item.role_name) {
            if (item.label) {
              item["role_name"] = item.label;
              delete item["label"];
            }
          }
        });
      }
      return item;
    });
    return d;
  };
  /*Toggle feature-------STARTSSS*/
  setToggleButton = (name, data, type) => {
    let formRes = this.state.formData.org_details;
    let toggleRes = this.state.checkedResponse;

    if (toggleRes && toggleRes.length > 0) {
      if (Array.isArray(toggleRes) && toggleRes.length > 0) {
        const index = toggleRes.findIndex((item) => item.org.keys === name);

        let toggle = [];
        if (index !== -1) {
          toggle = toggleRes[index]["status"];
          return toggle === 0 ? true : false;
        }
      }
    } else {
      if (Array.isArray(formRes) && formRes.length > 0) {
        const index = formRes.findIndex((item) => item.org.keys === name);
        let toggle = [];
        if (index !== -1) {
          toggle = formRes[index]["status"];
          return toggle === 0 ? true : false;
        }
      }
    }
  };

  toggleOrg = (eleId, itemOrgid) => {
    if (this.state.formData.type === "edit") {
      if (!this.state.plusButtonClick) {
        return (
          <Form.Item
            style={{
              marginRight: "auto",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: 28 }}>
              <Button
                style={{ display: "contents" }}
                size="middle"
                icon={
                  <Switch
                    checked={!this.setToggleButton(eleId, "disable2")}
                    onClick={(e) => this.handleToggle(eleId, e)}
                    loading={this.state.rowLoader}
                  />
                }
              />
            </div>
          </Form.Item>
        );
      }
    }
  };

  handleToggle = (val, checkedVal, itemOrgid) => {
    let toggleData = {};
    let formData = this.state.formData.org_details;
    let toggleRes = this.state.checkedResponse;

    if (toggleRes && toggleRes.length > 0) {
      toggleRes.filter((item) => {
        if (item.org.keys === val) {
          toggleData = item;
        }
      });
    } else {
      this.setState((pr) => {
        console.log(pr, "formsMe222");
      });
      formData.filter((item) => {
        if (item.org.keys === val) {
          toggleData = item;
        } else if (
          this.state.rolePrevord &&
          this.state.rolePrevord.length > 0
        ) {
          this.state.rolePrevord.filter((item) => {
            if (item.org.keys === val) {
              toggleData = item;
            }
          });
        }
      });
    }
    let body = {
      provider_id: this.state.formData.user_id,
      org_id: toggleData.org.org_id,
      status: checkedVal === true ? 1 : 0,
    };

    ApiService.adddetails
      .activateUserOrg(body)
      .then((data) => {
        this.setState({ rowLoader: true });
        if (data.status === 200) {
          this.openNotification(body.status);
          this.setState({ checkedStatus: checkedVal });
          this.setState({ checkedResponse: data.data });

          this.setState(
            {
              formData: {
                ...this.state.formData,
                org_details: data.data,
              },
            },
            function () {
              console.log(this.state.formData, "pree");
            }
          );

          this.loadUserInitialroleFileds(data.data);
          // this.formRef.current.resetFields()
          // this.loadInitalFiles()
        }
      })
      .catch((error) => {
        console.log(error, "Error During Toggleing User Org");
      });
  };

  /*Toggle feature-------ENDSSSS*/
  setDefaultRolVal = (name, fieldname, val) => {
    if (this.formRef.current) {
      setTimeout(() => {
        const details = this.state.checkedResponse;
        if (Array.isArray(details) && details.length > 0) {
          const index = details.findIndex(
            (item) => item.org.keys === fieldname
          );
          let val = [];
          if (index !== -1) {
            val = details[index];
            val = [
              ...val.rol.map((x) => ({
                key: x.role_id,
                label: x.role_name || x.label,
                value: x.role_id || x.value,
              })),
            ];
          }
          if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
              [name]: val,
            });
          }
        }
      }, 1500);
    } else {
      setTimeout(() => {
        const details = this.state.formData.org_details;
        if (Array.isArray(details) && details.length > 0) {
          const index = details.findIndex(
            (item) => item.org.keys === fieldname
          );
          let val = [];
          if (index !== -1) {
            val = details[index];
            val = [
              ...val.rol.map((x) => ({
                key: x.role_id,
                label: x.role_name || x.label,
                value: x.role_id || x.value,
              })),
            ];
          }

          if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
              [name]: val,
            });
          }
        }
      }, 1500);
    }
    return [""];
  };

  setDefaultOrgVal = (name, role, val) => {
    if (this.formRef.current) {
      setTimeout(() => {
        console.log(this.formRef.current, "settimeout ref");
        const details = this.state.checkedResponse;
        if (Array.isArray(details) && details.length > 0) {
          const index = details.findIndex((item) => item.org.keys === name);
          let val = [];
          if (index !== -1) {
            val = details[index]["org"];
            console.log(val, "corona");
            val = [{ label: val.name, value: val.org_id }];
          }

          if (this.formRef.current) {
            console.log(val, "address");
            this.formRef.current.setFieldsValue({
              [name]: val,
            });
          }
        }
      }, 1500);
      // }, 1500)
    } else {
      setTimeout(() => {
        console.log(this.formRef.current, "settimeout ref");
        const details = this.state.formData.org_details;
        console.log(details, "deailss");
        if (Array.isArray(details) && details.length > 0) {
          const index = details.findIndex((item) => item.org.keys === name);
          let val = [];
          if (index !== -1) {
            val = details[index]["org"];
            val = [{ label: val.name, value: val.org_id }];
          }
          if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
              [name]: val,
            });
          }
        }
      }, 1500);
    }
    //return [""];
  };

  addOrg = (type, data, tagRole, tagOrg) => {
    // if()

    if (type !== "add") {
      console.log(data, "orgn");
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          this.setState({
            org: data[i]["org"]["org_id"],
          });
        }
        return data.map((item) => {
          let itemOrgid = null;
          let disabled = false;
          const fieldname = this.getOrgFieldsKeyNam(
            "Organization",
            item.org.org_id
          );
          if (item.org && !item.org.keys) {
            item.org.keys = fieldname;
          }
          let defval = [];
          this.state.formData.org_details.map((roles) => {
            if (roles.rol) {
              defval = [
                ...roles.rol.map((x) => ({
                  key: x.role_id,
                  label: x.role_name || x.label,
                  value: x.role_id || x.value,
                })),
              ];
            }
          });

          const selectedorganization = [
            {
              key: item.org.org_id,
              value: item.org.org_id,
              label: item.org.name,
            },
          ];
          // const selectedorganization = item.org.org_id
          // console.log(this.formRef, 'rerw')
          const roleFiledname = this.getOrgFieldsKeyNam(
            "Roles",
            item.org.org_id
          );
          return (
            <Input.Group
              compact
              style={{ display: "flex", alignItems: "center" }}
              key={fieldname}
            >
              {sessionStorage.getItem("rolename") === "Super Admin" && (
                <Form.Item
                  name={fieldname}
                  label="Organization"
                  className="display"
                  rules={[
                    {
                      required: true,
                      message: "Please select the Organization",
                    },
                  ]}
                  style={{
                    width: "45%",
                    marginRight: "auto",
                    pointerEvents: type === "edit" ? "none" : "",
                  }}
                >
                  {/* {
                                    sessionStorage.getItem("rolename") === "Admin" && (
                                        disabled = true
                                    )} */}

                  <Select
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      // padding: "3px",
                    }}
                    disabled={this.setToggleButton(fieldname, item, "disable")}
                    showSearch
                    labelInValue
                    placeholder="Select a Organization"
                    optionFilterProp="children"
                    // defaultValue={}
                    onChange={(e) => {
                      console.log(e);

                      if (
                        sessionStorage.getItem("rolename") === "Super Admin"
                      ) {
                        itemOrgid = e.value;
                        this.handleUserOrg(itemOrgid, fieldname, e);
                      } else {
                        itemOrgid = sessionStorage.getItem("org_id");
                        this.handleUserOrg(itemOrgid, fieldname, e);
                      }
                    }}
                    onFocus={() => null}
                    onBlur={() => null}
                    onSearch={() => null}
                    // defaultValue={item.org.name || ''}
                    value={this.setDefaultOrgVal(
                      fieldname,
                      selectedorganization
                    )}
                    // value={[item.org.org_id]}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // style={{
                    //   width: "100%",
                    // }}
                  >
                    {/* <Option key={item.org.org_id} value={item.org.org_id}>
                                        {item.org.name}
                                    </Option> */}
                    {tagOrg &&
                      tagOrg.map((orgitem, index) => {
                        itemOrgid = orgitem.org_id;
                        return (
                          <Option key={orgitem.org_id} value={orgitem.org_id}>
                            {orgitem.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                name={roleFiledname}
                label="Roles"
                className="display"
                rules={[
                  {
                    required: true,
                    message: "Please select the role",
                  },
                ]}
                style={{ width: "45%", marginRight: "auto" }}
              >
                <Select
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    // padding: "3px",
                  }}
                  disabled={this.setToggleButton(fieldname, item, "disable")}
                  showSearch
                  labelInValue
                  mode="multiple"
                  placeholder="Select the roles"
                  optionFilterProp="children"
                  onChange={(c) => {
                    sessionStorage.getItem("rolename") === "Admin" &&
                      this.handleUserRole(
                        c,
                        sessionStorage.getItem("org_id"),
                        fieldname
                      );
                    sessionStorage.getItem("rolename") === "Super Admin" &&
                      this.handleUserRole(c, itemOrgid, fieldname);
                  }}
                  // onChange={async (c) => {
                  //         this.handleUserRole(c, itemOrgid, fieldname)
                  // }}
                  //onClick ={()=>this.userRoleCheck(fieldname)}
                  onFocus={() => null}
                  onBlur={() => null}
                  onSearch={() => null}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={this.setDefaultRolVal(
                    roleFiledname,
                    fieldname,
                    fieldname,
                    defval
                  )}
                  // defaultValue={defval}
                >
                  {tagRole &&
                    tagRole.map((roleitem, index) => {
                      return (
                        <Option key={roleitem.role_id} value={roleitem.role_id}>
                          {roleitem.role_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {this.toggleOrg(fieldname, itemOrgid)}
              {this.addRemoveOrgEle(fieldname, itemOrgid)}
            </Input.Group>
          );
        });
      }
    } else {
      let disabled = false;
      let itemOrgid = null;
      const fieldname = this.getOrgFieldsKeyNam("Organization");
      const roleFiledname = this.getOrgFieldsKeyNam("Roles");
      // const fieldname_roles = this.getOrgFieldsKeyNam('Organization')
      return (
        <Input.Group compact style={{ display: "flex" }} key={fieldname}>
          {sessionStorage.getItem("rolename") === "Super Admin" && (
            <Form.Item
              name={fieldname}
              label="Organization"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please select the Organization",
                },
              ]}
              style={{ width: "45%", marginRight: "auto" }}
            >
              {/* {
                        sessionStorage.getItem("rolename") === "Admin" && (
                            disabled = true
                        )} */}

              <Select
                // disabled={disabled}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  // padding: "3px",
                }}
                id={fieldname}
                showSearch
                placeholder="Select a Organization"
                optionFilterProp="children"
                labelInValue
                onChange={(e) => {
                  if (sessionStorage.getItem("rolename") === "Super Admin") {
                    itemOrgid = e.value;
                    this.handleUserOrg(itemOrgid, fieldname, e);
                  } else {
                    itemOrgid = sessionStorage.getItem("org_id");
                    this.handleUserOrg(itemOrgid, fieldname, e);
                  }
                }}
                onFocus={() => null}
                onBlur={() => null}
                onSearch={() => null}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {tagOrg &&
                  tagOrg.map((orgitem, index) => {
                    //   itemOrgid = orgitem.org_id;
                    return (
                      <Option key={orgitem.org_id} value={orgitem.org_id}>
                        {orgitem.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name={roleFiledname}
            disabled
            label="Roles"
            className="display"
            rules={[
              {
                required: true,
                message: "Please select the role",
              },
            ]}
            style={{ width: "45%", marginRight: "auto" }}
          >
            <Select
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                // padding: "3px",
              }}
              showSearch
              mode="tags"
              labelInValue
              placeholder="Select the roles"
              optionFilterProp="children"
              onChange={(c) => {
                sessionStorage.getItem("rolename") === "Admin" &&
                  this.handleUserRole(
                    c,
                    sessionStorage.getItem("org_id"),
                    roleFiledname,
                    fieldname
                  );
                sessionStorage.getItem("rolename") === "Super Admin" &&
                  this.handleUserRole(c, itemOrgid, roleFiledname, fieldname);
              }}
              // onChange={(c) => {
              //     this.handleUserRole(c, itemOrgid, roleFiledname, fieldname)
              // }}
              onFocus={() => null}
              onBlur={() => null}
              onSearch={() => null}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {tagRole &&
                tagRole.map((roleitem, index) => {
                  return (
                    <Option key={roleitem.role_id} value={roleitem.role_id}>
                      {roleitem.role_name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          {this.toggleOrg(fieldname, itemOrgid)}
          {this.addRemoveOrgEle(fieldname, itemOrgid)}
        </Input.Group>
      );
    }
  };
  userRoleCheck = (fieldname) =>
    new Promise((resolve, reject) => {
      if (this.state.checkedResponse && this.state.checkedResponse.length > 0) {
        let toggleData = this.state.formData.org_details.filter((item) => {
          if (item.org.keys === fieldname) {
            return item.org.name;
          }
        });

        let toggleDataStatus = this.state.checkedResponse.filter((item) => {
          if (item.org.name === toggleData[0].org.name) {
            return item;
          }
        });

        if (toggleDataStatus[0].status === 0) {
          this.setState({ disableStatus: true });
          return reject(alert("Please Activate this organization"));
        }
      } else {
        let toggleData = this.state.formData.org_details.filter((item) => {
          if (item.org.keys === fieldname) {
            return item.org.name;
          }
        });
        if (toggleData[0].status === 0) {
          console.log(
            this.formRef.current.validateFields([toggleData[0].org.name]),
            "val"
          );
          reject(alert("Please Activate this organization"));
        }
      }

      return;
    });

  handleUserRole = async (c, itemOrgid, fieldname, orgname) => {
    //await this.userRoleCheck(fieldname)
    if (!itemOrgid) {
      // throw new Error('Invalid')
      this.formRef.current.setFieldsValue({
        [fieldname]: [],
      });
      this.formRef.current.validateFields([orgname]);
      message.config({
        top: 0,
        rtl: true,
        duration: 2,
      });

      message.info(
        "Please select the origanization before selecting the roles."
      );
      return;
    } else {
      this.setState((p) => {
        //const corg = this.state.formData.org_details
        let prevord = p.formData.org_details || [];

        // let storePrevord = prevord.map(item => {
        //     return item.org
        // })

        // if (prevord[0].org.keys) {
        //     sessionStorage.setItem('prevord', JSON.stringify(storePrevord))
        // }

        const itemindex = prevord.findIndex((olditem) => {
          if (olditem.org) {
            if (olditem.org.org_id === itemOrgid) {
              return true;
            }
          }
        });

        // if (c[itemindex]["label"] !== "Submitter Provider") {
        //   if (itemindex !== -1) {
        //     prevord[itemindex].rol = c;
        //     return { formData: { ...p.formData, org_details: prevord } };
        //   }
        // } else if (c[itemindex]["label"] === "Submitter Provider") {
        //   // console.log(c[itemindex]["label"], "iit");
        //   if (itemindex !== -1) {
        //     this.setState({
        //       display: true,
        //     });
        //     prevord[itemindex].rol = c;
        //     return { formData: { ...p.formData, org_details: prevord } };
        //   }
        // }

        if (itemindex !== -1 && c[itemindex] !== undefined) {
          console.log(c, "cc");
          for (let i = 0; i < c.length; i++) {
            if (c[i]["label"] === "Submitter Provider") {
              this.setState({
                display: true,
              });
            }
          }

          prevord[itemindex].rol = c;
          return { formData: { ...p.formData, org_details: prevord } };
        } else if (itemindex !== -1) {
          prevord[itemindex].rol = c;
          return { formData: { ...p.formData, org_details: prevord } };
        } else {
          //this.setState({ rolePrevord: prevord });
          let d = [];
          let rolOrg;
          prevord.map((item) => {
            if (item.org.keys === fieldname) {
              rolOrg = item;
            }
          });
          if (rolOrg === undefined) {
            message.error(
              "please select organization before selecting the roles"
            );
            if (itemindex !== -1) {
              prevord[itemindex].rol = "";
              return { formData: { ...p.formData, org_details: prevord } };
            }
            return;
            //window.location.reload("/add-user");
          }
          // if(c === )
          d.push({
            // org: {
            //     org_id: this.state.org ? this.state.org : itemOrgid,
            //     name: 'ddd',
            // },
            org: rolOrg.org,
            rol: c,
          });
          prevord = d;
        }
        return { formData: { ...p.formData, org_details: prevord } };
        // let org_details = [...prevord, ...c]
      });
    }
  };

  handleUserOrg = (itemOrgid, fieldname, e) => {
    // this.setState({id: itemOrgid})
    //  this.getRoleList()
    console.log(itemOrgid, "1");
    try {
      const stateOrgdetais = this.state.formData.org_details;
      console.log(this.state.formData.org_details, "stateorg");
      this.setState((p) => {
        let org_details = p.formData.org_details;

        if (org_details && org_details.length > 0) {
          const exists = stateOrgdetais.filter((item) => {
            if (item.org.org_id === e.value) {
              this.handleRemoveOrgFeild(fieldname, null);
              return true;
            }
          });

          if (exists.length) {
            return;
          }

          const existingField = stateOrgdetais.findIndex((item) => {
            if (item.org.keys === fieldname) {
              return true;
            }
          });
          //  if(sessionStorage.getItem("rolename") === "Super Admin") {
          let orgObj = {
            org: {
              keys: fieldname,
              name: e.label,
              org_id: e.value,
            },
            rol: {},
          };
          // }
          if (sessionStorage.getItem("rolename") === "Admin") {
            let orgObj = {
              org: {
                keys: fieldname,
                name: e.label,
                org_id: sessionStorage.getItem("org_id"),
              },
              rol: {},
            };
          }

          if (existingField !== -1) {
            stateOrgdetais[existingField].org = orgObj.org;
            return;
          }

          // item.
          org_details.push(orgObj);
          return {
            ...p,
            formData: { ...p.formData, org_details },
          };
        }
        if (
          !org_details ||
          (typeof org_details === "object" &&
            Object.keys(org_details).length < 1)
        ) {
          let item = [];
          //  if(sessionStorage.getItem("rolename") === "Super Admin") {
          let orgObj = {
            org: {
              keys: fieldname,
              name: e.label,
              org_id: e.value,
            },
            rol: {},
          };
          //}

          if (sessionStorage.getItem("rolename") === "Admin") {
            let orgObj = {
              org: {
                keys: fieldname,
                name: e.label,
                org_id: sessionStorage.getItem("org_id"),
              },
              rol: {},
            };
          }

          item.push(orgObj);
          org_details = item;

          return {
            ...p,
            formData: { ...p.formData, org_details },
          };
        }
        return { formData: { ...p.formData } };
      });
    } catch (error) {
      debugger;
      console.log(error);
    }
  };
  time = () =>
    Date.now() ||
    function () {
      return +new Date();
    };
  getOrgFieldsKeyNam = (field, id) => {
    console.log(this.time());
    // return field + this.time()         //commented coz this.time() not creating unique IDs
    return field + this.time() + id;
  };

  addRemoveOrgEle = (eleId, itemOrgid) => {
    if (
      this.state.orgFileds &&
      this.state.orgFileds.length > 0 &&
      this.state.plusButtonClick
    ) {
      return (
        <Form.Item
          style={{
            marginRight: "auto",
            alignItems: "center",
          }}
        >
          <Button
            size="middle"
            onClick={() => this.handleRemoveOrgFeild(eleId, itemOrgid)}
            icon={<MinusOutlined />}
          />
        </Form.Item>
      );
    }
  };
  openNotification = (status) => {
    notification.open({
      style: { lineHeight: 1 },
      duration: 5.9,
      description: (
        <span style={{ fontSize: 20 }}>
          {status === 1 ? (
            <span style={{ color: "#4BB543" }}>
              Activating this Organization
            </span>
          ) : (
            <span style={{ color: "#FF9494" }}>
              Deactivating this Organization
            </span>
          )}
        </span>
      ),
    });
  };

  handleRemoveOrgFeild = (eleId, id) => {
    const { orgFileds, formData } = this.state;
    let org_details = formData.org_details;
    const indexId = orgFileds.findIndex((item) => {
      if (item.key === eleId) {
        return true;
      }
    });
    if (indexId !== -1) {
      orgFileds.splice(indexId, 1);

      let index = org_details.findIndex((item) => {
        if (item.org.keys === eleId) {
          return true;
        }
      });
      if (index !== -1) {
        org_details.splice(index, 1);
      }
    }
    this.setState({
      orgFileds,
      formData: { ...this.state.formData, org_details },
    });
  };
  loadUserInitialroleFileds = (statusData) => {
    this.setState({ plusButtonClick: false });
    this.setState({ rowLoader: false });
    this.setState((pr) => {
      const { tagRole, tagOrg, formData } = this.state;
      let type = this.formType;
      const data = formData.org_details;
      if (typeof data === "object" && Object.keys(data).length < 1) {
        type = "add";
      }
      let orgFileds = Array.from(
        new Set([this.addOrg(type, data, tagRole, tagOrg)])
      );
      return {
        ...pr,
        orgFileds,
        initialFormLoad: true,
      };
    });
  };

  loadInitalFiles = async () => {
    await this.getSpecialityList();
    await this.getRoleList();
    await this.getOrgTypes();
    await this.fetchSubGroups();
    if (this.formType === "edit" || this.formType === "delete") {
      await this.doView();
    }
    this.loadUserInitialroleFileds();
  };

  AddMoreOrgFeild = async () => {
    this.setState({ plusButtonClick: true });
    const val = await this.formRef.current.validateFields();
    this.setState((pr) => {
      const { tagRole, tagOrg, formData } = this.state;
      const type = formData.type;
      const data = formData.org_details;
      console.log("data before1", formData.type);
      console.log("data before2", formData.org_details);
      //console.log("data before1", formData.org_details);
      let orgFileds = pr.orgFileds;
      let newfiled = Array.from(
        new Set([this.addOrg("add", data, tagRole, tagOrg)])
      );
      console.log("data before6", data, "tagrole", tagRole, "tagOrg", tagOrg);

      console.log("data before3", orgFileds);
      // console.log("data before4", newfiled);
      orgFileds = [...orgFileds, ...newfiled];
      console.log("data before4", newfiled);
      console.log("data before8", orgFileds);
      return {
        ...pr,
        orgFileds,
      };
    });
  };

  resetPassword = () => {
    this.setState({ resetLoading: true });
    ApiService.adddetails
      .forgotPassword({ email: this.state.formData.email })
      .then((data) => {
        this.setState({ resetLoading: false });
        if (data.status === 200) {
          message.success("Password Reset Mail has been sent successfully.");
        }
      })
      .catch((error) => {
        console.log(error, "resetError");
      });
  };

  componentDidMount() {
    const { formData } = this.state;

    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    if (this.state.token === null || this.state.token === "") {
      this.props.history.push("/login");
      window.location.reload(true);
    }

    if (isLoggedIn === "false") {
      this.props.history.push("/login");
    }

    this.loadInitalFiles();

    // console.log("abc",this.state.formData.type)
    if (this.state.formData.type === "edit") {
      this.setState({ buttonName: "Update" });
    } else if (this.state.formData.type === "delete") {
      this.setState({ buttonName: "Delete", isDisabled: true });
    } else {
      this.setState({
        formData: { ...formData, dob: "" },
      });
      this.setState({ buttonName: "Save" });
    }

    message.config({ top: 150, duration: 2 });
  }

  fetchSubGroups = () => {
    let body = {
      type: "list_user",
    };
    ApiService.adddetails
      .subGroups(body)
      .then((data) => {
        //console.log(data)
        this.setState({ isFetchSpeciality: true });
        if (data.status === 200) {
          let resData = Array.from(data.data);
          let newData = [];
          console.log(resData);

          for (let i = 0; i < resData.length; i++) {
            newData.push({
              id: resData[i]["id"],
              name: resData[i]["name"],
              status: resData[i]["status"],
            });
          }
          this.setState({
            closecodeFullyLoaded: true,
            subgroupData: newData,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ isFetchSpeciality: false });
      });
  };

  /*Function for clear text field  */
  clearData() {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        first_name: "",
        last_name: "",
        " hospital_name": "",
        email: "",
        spec_id: "",
        role_id: "",
        about: "",
        " mobile": "",
        " experience": "",
      },
      error: "",
    });
    this.props.history.push("/user-list");
  }

  getOrgDetailss = () => {
    const data = this.state.formData.org_details;
    if (sessionStorage.getItem("rolename") === "Admin") {
      return data.map((item) => {
        return {
          org_id: sessionStorage.getItem("org_id"),
          roles:
            item.rol &&
            item.rol.map((rols) => {
              return rols.role_id || rols.value;
            }),
          status: item.status === undefined ? 1 : item.status,
        };
      });
    }
    if (sessionStorage.getItem("rolename") === "Super Admin") {
      return data.map((item) => {
        return {
          org_id: item.org.org_id,
          roles:
            item.rol &&
            item.rol.map((rols) => {
              return rols.role_id || rols.value;
            }),
          status: item.status === undefined ? 1 : item.status,
          //previous code was only for super admin and check null not undefined
          //undefined was for production issue fix
        };
      });
    }
  };

  /*Api for save Doctor or provider */
  doSave() {
    const { formData } = this.state;
    this.setState({ isLoading: true });
    if (
      this.state.formData.experience === null ||
      this.state.formData.experience === undefined
    ) {
      this.setState({
        formData: { ...formData, ["experience"]: "" },
      });
    }
    let body = {
      npi: this.state.formData.npi || "0000000000",
      title: this.state.formData.title,
      first_name:
        this.state.formData.first_name.charAt(0).toUpperCase() +
        this.state.formData.first_name.slice(1),
      last_name:
        this.state.formData.last_name.charAt(0).toUpperCase() +
        this.state.formData.last_name.slice(1),
      dob:
        this.state.formData.dob === "undefined-undefined-undefined"
          ? ""
          : this.state.formData.dob === null
          ? ""
          : this.state.formData.dob,
      gender: this.state.formData.gender,
      hospital_name: this.state.formData.hospital_name || "",
      medical_group: this.state.formData.medical_group || "",
      email: this.state.formData.email,
      password: this.checkPassword(this.state.formData.password),
      experience:
        this.state.formData.experience === ""
          ? 0
          : parseInt(this.state.formData.experience),
      image_url: sessionStorage.getItem("userProfile")
        ? sessionStorage.getItem("userProfile")
        : "",
      specialties: this.state.formData.specialties,
      spec_id: this.state.formData.specialties[0],
      about: this.state.formData.about,
      Dashboard_amount: this.state.formData.Dashboard_amount
        ? this.state.formData.Dashboard_amount
        : 0,
      Dashboard_Volume: this.state.formData.Dashboard_Volume
        ? this.state.formData.Dashboard_Volume
        : 0,
      Dashboard_non_face_to_face: this.state.formData.Dashboard_non_face_to_face
        ? this.state.formData.Dashboard_non_face_to_face
        : 0,
      Dashboard_Response_time: this.state.formData.Dashboard_Response_time
        ? this.state.formData.Dashboard_Response_time
        : 0,
      responder_status: this.state.formData.responder_status
        ? this.state.formData.responder_status
        : 0,
      // "user_id": this.state.formData.user_id,
      org_details: this.getOrgDetailss(),
      // "org_id": this.state.formData.org_id,
    };
    if (this.state.formData.mobile) {
      body.mobile =
        DOMAIN_NAME === "https://staging.afya.chat"
          ? "+91" + this.state.formData.mobile
          : "+1" + this.state.formData.mobile;
    }
    console.log(body, "doctor");
    console.log("data before5", body);
    ApiService.adddetails
      .addDoctor(body)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status === 200) {
          sessionStorage.removeItem("userProfile");
          message.success("Successfully added the user");
          this.props.history.push("/user-list");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error?.data?.status === 702) {
          message.error("Mail already exist");
        }
        if (error?.data?.status === 709) {
          message.error("Npi already exist");
        }
      });
  }

  doView = () =>
    new Promise((res, rej) => {
      // alert("view api")
      this.setState({ isViewLoading: true });

      let body = {
        user_id: this.state.formData.user_id,
      };
      ApiService.adddetails
        .viewDoctor(body)
        .then((data) => {
          // this.setState({ formData: data.data });
          if (typeof data.data === "object") {
            const respon = Object.assign({}, data.data);
            const items = Object.keys(respon);
            items.map((key) => {
              if (!respon[key]) {
                respon[key] = "";
              }
              if (key === "org_details") {
                const ifnull = respon[key].filter((item) => {
                  if (!item.org) {
                    return true;
                  }
                });
                if (ifnull.length) {
                  respon[key] = {};
                }
              }
            });
            if (this.state.checkedResponse.length) {
              message.success("Successfully updated the organization");
            }
            this.setState(
              {
                formData: {
                  ...this.state.formData,
                  ...respon,
                  about: respon.bio ? respon.bio : respon.about,
                },
              },
              function () {
                console.log(this.state.formData, "sett");
              }
            );
            res("");
          }
        })
        .catch((error) => {
          this.setState({ isViewLoading: false });
          console.log(error);
        });
    });
  checkPassword = (pass) => {
    if (typeof pass === "string" && pass.trim().length > 6) {
      return pass;
    }
    return undefined;
  };

  updateSave() {
    this.setState({ isLoading: true });
    let body = {
      npi: this.state.formData.npi || "0000000000",
      title: this.state.formData.title,
      first_name:
        this.state.formData.first_name.charAt(0).toUpperCase() +
        this.state.formData.first_name.slice(1),
      last_name:
        this.state.formData.last_name.charAt(0).toUpperCase() +
        this.state.formData.last_name.slice(1),
      dob:
        this.state.formData.dob === "undefined-undefined-undefined"
          ? ""
          : this.state.formData.dob === null
          ? ""
          : this.state.formData.dob,
      //gender: this.state.formData.gender,

      hospital_name: this.state.formData.hospital_name,
      email: this.state.formData.email,
      password: this.checkPassword(this.state.formData.newpassword),
      specialties: this.state.formData.specialties
        ? this.state.formData.specialties
        : null,
      spec_id: this.state.formData.specialties[0],
      about: this.state.formData.about || "",
      experience:
        this.state.formData.experience === ""
          ? 0
          : parseInt(this.state.formData.experience),
      image_url: sessionStorage.getItem("userProfile")
        ? sessionStorage.getItem("userProfile")
        : this.state.formData.image_url,
      user_id: this.state.formData.user_id,
      medical_group: this.state.formData.medical_group,
      Dashboard_amount: this.state.formData.Dashboard_amount
        ? this.state.formData.Dashboard_amount
        : 0,
      Dashboard_Volume: this.state.formData.Dashboard_Volume
        ? this.state.formData.Dashboard_Volume
        : 0,
      Dashboard_non_face_to_face: this.state.formData.Dashboard_non_face_to_face
        ? this.state.formData.Dashboard_non_face_to_face
        : 0,
      Dashboard_Response_time: this.state.formData.Dashboard_Response_time
        ? this.state.formData.Dashboard_Response_time
        : 0,
      responder_status: this.state.formData.responder_status
        ? this.state.formData.responder_status
        : 0,
      org_details: this.getOrgDetailss(),
    };
    console.log("data before saving!!!", body);

    if (this.state.formData.mobile) {
      body.mobile =
        this.state.formData.mobile.length === 10
          ? DOMAIN_NAME === "https://staging.afya.chat"
            ? "+91" + this.state.formData.mobile
            : "+1" + this.state.formData.mobile
          : this.state.formData.mobile;
    } else {
      // body.mobile = DOMAIN_NAME === "https://staging.afya.chat" ? "+91" : "+1";
      body.mobile = "";
    }
    console.log(body, "bodddd");

    ApiService.adddetails
      .editDoctor(body)
      .then((data) => {
        // alert("edit api")
        this.setState({ isLoading: false });
        if (data.status === 200) {
          sessionStorage.removeItem("userProfile");
          message.success("Successfully updated the user");
          this.props.history.push("/user-list");
        } else if (data.statusCode === 500) {
          message.error("Company already exist");
        } else if (data.statusCode === 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        if (error.data.statusCode === 500) {
          message.error("Company already exist");
        }
        if (error.data.status === 709) {
          message.error("Npi already exist");
        }
      });
  }

  // deleteDoctorList() {
  //     let body={

  //         "user_id":this.state.formData.user_id,

  //     }
  //     ApiService.adddetails.deleteDoctor(body).then(data => {
  //         this.setState({ isLoading: false })
  //         if (data.status === 200) {
  //             message.success('Successfully deleted the Doctor');
  //             this.props.history.push('/admin')
  //         }

  //     })
  //         .catch(error => {
  //             this.setState({ isLoading: false })

  //         });
  // }

  getValues = (field, checkedValues) => {
    const { formData } = this.state;
    if (checkedValues.target) {
      if (checkedValues.target.type === "checkbox") {
        let formData = this.state.formData;
        this.setState({
          formData: {
            ...formData,
            [field]: checkedValues.target.checked ? 1 : 0,
          },
        });
        return;
      }
    }
  };

  validateForm = async () => {
    const data = this.state.formData;
    const { formData } = this.state;
    const val = await this.formRef.current.validateFields();
    console.log("typestate", this.state);

    if (data === "") {
      message.warn("Please fill the details");
    } else {
      // alert("hey there")
      if (this.state.buttonName === "Update") {
        // alert("edit")
        this.updateSave();
      } else if (this.state.formData.type === "add") {
        // alert("save")
        this.doSave();
      } else if (this.state.buttonName === "Delete") {
        // alert("delete")
        // this.deleteDoctorList()
      }
    }
  };

  handleChange = (e, field) => {
    const { formData, submitted } = this.state;

    if (field === "npi") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "title") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "first_name") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "last_name") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "dob") {
      e = moment(e).format("MM-DD-YYYY");
      // console.log(e,'111')
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "hospital_name") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "medical_group") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "email") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "gender") {
      console.log(e.target.value);

      this.setState(
        {
          formData: { ...formData, [field]: e.target.value },
        },
        function () {}
      );
    } else if (field === "mobile") {
      console.log(e, "mobileee");
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "experience") {
      let experience = {};
      const value = e;
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "") {
        experience = { ...formData, [field]: value };
      }
      this.setState(
        {
          formData: experience,
        },
        function () {}
      );
    } else if (field === "about") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "specialties") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {
          console.log();
        }
      );
    } else if (field === "role_id") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {
          console.log();
        }
      );
    } else if (field === "org_id") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "password") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else if (field === "newpassword") {
      this.setState(
        {
          formData: { ...formData, [field]: e },
        },
        function () {}
      );
    } else {
      this.setState(
        {
          formData: { ...formData, [field]: e.target.value },
        },
        function () {}
      );
    }
  };

  getSpecialityList = () =>
    new Promise((res, rej) => {
      let body = {
        dob: "",
      };
      ApiService.adddetails.getSplType(body).then((data) => {
        if (data.status === 200) {
          const newData =
            (data.data && data.data.filter((x) => x.status !== 0)) || [];

          this.setState({ tagSpl: newData });
          res("");
        }
      });
    });

  getOrgTypes = () =>
    new Promise((res, rej) => {
      ApiService.adddetails.getOrgList().then((data) => {
        if (data.status === 200) {
          const newData = data.data.filter((item) => {
            if (item.name && item.org_id) {
              if (item.status !== 0) {
                return item;
              }
            }
          });
          res("");
          this.setState({ tagOrg: newData });
        }
      });
    });

  getRoleList = () =>
    new Promise((res, rej) => {
      // const {id} = this.state
      // console.log(id,'iii')
      ApiService.adddetails.getRoleType().then((data) => {
        if (data.status === 200) {
          let roleData = data.data.response.filter(
            (item) =>
              // item.role_name === "Responder Afya" ||
              item.role_name === "Submitter Provider" ||
              item.role_name === "Responder Provider"
          );
          this.setState({ tagRole: roleData });
          res("");
        }
      });
    });

  signOut() {
    const { setLoggin } = this.context;
    setLoggin("false");
    this.props.history.push("/login");
    sessionStorage.setItem("isLoggedIn", false);
  }

  fetchOrgVal = () => {
    return this.state.getOrgVal;
  };

  renderOrgFields = () => {
    return this.state.orgFileds.map((item) => item);
  };

  render() {
    // if(this.state.formData.org_details && this.state.formData.org_details.length > 0) {
    //     for(let i=0; i < this.state.formData.org_details.length; i++) {
    //     console.log(this.state.formData.org_details[i].rol[i].role_name,'ooor')
    //     }
    // }

    //  console.log(this.formRef, 'reff')

    const { tagSpl, tagRole, tagOrg, subgroupData, initialFormLoad } =
      this.state;
    // let splTag = tagSpl.sort(function (a, b) {
    //     return a.name.localeCompare(b.name);

    // });

    let orgTag = tagOrg.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    // let orgTag = tagOrg && tagOrg.filter(item => !item.name.toLowerCase().match(/select/g))

    // let listOrg = tagOrg.length > 0 &&
    // tagOrg.map((items, i) => {
    //         return (
    //             <option key={items.org_id} value={items.org_id}>{items.name}</option>
    //         )
    //     }, this);

    let disabledDate = (current) => {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    };

    let listSplTag =
      tagSpl.length > 0 &&
      tagSpl.map((items, i) => {
        return (
          <option key={items.spec_id} value={items.spec_id}>
            {items.name}
          </option>
        );
      }, this);

    let listGroupTag =
      subgroupData.length > 0 &&
      subgroupData.map((items, i) => {
        return (
          <option key={items.id} value={items.id}>
            {items.name}
          </option>
        );
      }, this);

    // let listRoleTag = tagRole.length > 0
    //     && tagRole.map((items, i) => {
    //         return (
    //             <option key={items.role_id} value={items.role_id}>{items.role_name}</option>
    //         )
    //     }, this);

    //  console.log(this.state.formData.first_name, 'this.state.formData.first_name ')
    const viewtype = this.state.formData.type;
    const loading =
      viewtype === "edit" ||
      (viewtype === "view" && this.state.formData.first_name === undefined);

    return !initialFormLoad ? (
      <>loading </>
    ) : (
      <div className="body-color">
        <Container>
          <div className="row-1">
            <h3 className="heading-font">Add User</h3>
            <ProfileUpload
              image_url={this.state.formData.image_url}
              userId={this.state.formData.user_id}
            />
          </div>
          <Form
            ref={this.formRef}
            // onFinish={() => this.validateForm()}
            initialValues={{
              npi: this.state.formData.npi,
              title: this.state.formData.title,
              First_Name: this.state.formData.first_name,
              Last_Name: this.state.formData.last_name,
              dob: this.state.formData.dob
                ? moment(this.state.formData.dob)
                : "",
              gender: this.state.formData.gender,
              Email_Id: this.state.formData.email,
              Mobile_Number: this.state.formData.mobile
                ? this.state.formData.mobile.substr(
                    this.state.formData.mobile.length - 10
                  )
                : "",
              Experience_In_Year: this.state.formData.experience,
              Hospital_Name: this.state.formData.hospital_name,
              Medical_group: this.state.formData.medical_group,
              // specialties: !this.state.formData.specialties
              //   ? this.state.formData.spec_id
              //   : this.state.formData.specialties,
              specialties: this.state.formData.specialties
                ? this.state.formData.specialties
                : [],
              About_Doctor: this.state.formData.about,
              Password: "",
            }}
          >
            <FormItem
              name="npi"
              label="NPI"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid NPI",
                },
                {
                  pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,

                  message: "Please enter a valid NPI",
                },
              ]}
            >
              <Input
                className="text-input-user-custom"
                id=""
                placeholder="NPI"
                value={this.state.formData.npi}
                onChange={(e) => this.handleChange(e.target.value, "npi")}
              />
            </FormItem>
            <FormItem
              name="title"
              label="Title"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter Title",
                },
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,

                  message:
                    "Please enter a valid Title without leading or trailing spaces",
                },
              ]}
            >
              <Input
                className="text-input-user-custom"
                id=""
                placeholder="Title"
                value={this.state.formData.title}
                onChange={(e) => this.handleChange(e.target.value, "title")}
              />
            </FormItem>
            <FormItem
              name="First_Name"
              label="First Name"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter your First Name",
                },
                {
                  pattern: /^(?!\s)[A-Za-z\s]*(?<!\s)$/,
                  message:
                    "Please enter a valid Name without leading or trailing spaces and only containing letters.",
                },
              ]}
            >
              <Input
                className="text-input-user-custom"
                id=""
                placeholder="First Name"
                // value={this.state.formData.first_name}
                onChange={(e) =>
                  this.handleChange(e.target.value, "first_name")
                }
              />
            </FormItem>
            <FormItem
              name="Last_Name"
              label="Last Name"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter your Last Name",
                },
                {
                  pattern: /^(?!\s)[A-Za-z\s]*(?<!\s)$/,
                  message:
                    "Please enter a valid Name without leading or trailing spaces and only containing letters.",
                },
              ]}
            >
              <Input
                className="text-input-user-custom"
                placeholder="Last Name"
                // value={this.state.formData.last_name}
                onChange={(e) => this.handleChange(e.target.value, "last_name")}
              />
            </FormItem>
            <Form.Item
              label="DOB"
              name="dob"
              className="display"
              rules={[
                {
                  required: false,
                  message: "Please enter a DOB",
                },
              ]}
            >
              <DatePicker
                className="text-input-user-custom"
                placeholder="Select or enter date in 'MM-DD-YYYY'"
                defaultValue={moment("", dateFormat)}
                disabledDate={disabledDate}
                format={dateFormat}
                value={this.state.formData.dob}
                onChange={(e) => this.handleChange(e, "dob")}
              />
            </Form.Item>
            {/* <Form.Item
                            label='Gender'
                            name="gender"
                            className="display"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose your gender'
                                },
                            ]}
                        >
                                <Radio.Group style={{display: 'flex'}} onChange={(e) => this.handleChange(e, 'gender')} value={this.state.formData.gender}>
                                    <Radio value={1}>Male</Radio>
                                    <Radio value={0}>Female</Radio>
                                </Radio.Group>
                          </Form.Item> */}
            <FormItem
              name="Email_Id"
              label="Email"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please enter your mail ID",
                },
                {
                  // pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/,
                  //pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-]+.[a-zA-Z0-9-]+$/,
                  pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input
                className="text-input-user-custom"
                type="email"
                placeholder="Email Id"
                value={this.state.formData.email}
                onChange={(e) => this.handleChange(e.target.value, "email")}
              />
            </FormItem>
            {this.state.formData.type === "add" && (
              <FormItem
                name="Password"
                label="Password"
                className="display"
                rules={[
                  {
                    required: true,
                    message: "Please enter the password",
                  },
                  {
                    // pattern: /^[A-Za-z]\w{7,14}$/,
                    //pattern:
                    //  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    //above pattern has been commented, to include all special chars
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};:'",<>\./?\\|`~])(?=.{8,})/,
                    message:
                      "Please enter a strong password (min 6 characters which contains atleast 1 special character,1 upper case, 1 lowecase and 1 numeric digit )",
                  },
                ]}
              >
                <Input.Password
                  className="text-input-user-custom"
                  placeholder="Password"
                  onChange={(e) =>
                    this.handleChange(e.target.value, "password")
                  }
                />
              </FormItem>
            )}
            <FormItem
              name="Mobile_Number"
              label="Mobile"
              className="display"
              rules={[
                // {
                //   required: true,
                //   message: "Please enter Mobile number",
                // },
                {
                  pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,

                  message: "Please enter a valid Phone number",
                },
              ]}
            >
              <Input
                className="text-input-user-custom"
                placeholder="Mobile Number"
                value={this.state.formData.mobile}
                onChange={(e) => this.handleChange(e.target.value, "mobile")}
              />
            </FormItem>
            <FormItem
              name="Experience_In_Year"
              label="Experience"
              className="display"
              rules={[
                {
                  required: false,
                  message: "Please enter your Experience",
                },
              ]}
            >
              <Input
                className="text-input-user-custom"
                id=""
                placeholder="Experience in year"
                type="number"
                step="1"
                min="1"
                value={this.state.formData.experience}
                onChange={(e) =>
                  this.handleChange(e.target.value, "experience")
                }
              />
            </FormItem>
            {/* <Select showSearch
                                                    className='text-select'
                                                        // mode="tags"
                                                        placeholder="Speciality"
                                                        onChange={(e) => this.handleChange(e, 'spec_id')}
                                                        value={this.state.formData.spec_id}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                     >
                                                         {listSplTag}
                                                  </Select> */}
            <FormItem
              name="Hospital_Name"
              label="Hospital"
              className="display"
              rules={[
                {
                  required: false,
                  message: "Please enter Hospital Name",
                },
                // {
                //     pattern: /^[a-zA-Z]*$/,
                //     message: 'Please enter a valid Name'

                // },
              ]}
            >
              <Input
                className="text-input-user-custom"
                placeholder="Hospital Name"
                value={this.state.formData.hospital_name}
                onChange={(e) =>
                  this.handleChange(e.target.value, "hospital_name")
                }
              />
            </FormItem>
            {/* commenting below code to show medical group in all the case , no validation for submitter provider or responder provider roles */}
            {/* {this.state.display === true && */}

            {this.state.formData.type === "add" && (
              <FormItem
                name="Medical_group"
                label="Medical Group/Clinic Organization"
                className="display"
                rules={[
                  {
                    required: true,
                    message: "Please enter medical group",
                  },
                ]}
              >
                <Select
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                  showSearch
                  // className="text-select-user-custom"
                  placeholder="Medical group"
                  onChange={(e) => this.handleChange(e, "medical_group")}
                  value={this.state.formData.medical_group}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {listGroupTag}
                </Select>
              </FormItem>
            )}
            {this.state.formData.medical_group !== "" &&
              this.state.formData.type === "edit" && (
                <FormItem
                  name="Medical_group"
                  label="Medical Group/Clinic Organization"
                  className="display"
                  rules={[
                    {
                      required: true,
                      message: "Please enter medical group",
                    },
                  ]}
                >
                  <Select
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                    showSearch
                    className="text-select"
                    placeholder="Medical group"
                    onChange={(e) => this.handleChange(e, "medical_group")}
                    value={this.state.formData.medical_group}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {listGroupTag}
                  </Select>
                </FormItem>
              )}
            {/* <AddOrg getValue={async (val) => {
                            let res = await Promise.resolve(val)
                            console.log(res, 'feth')
                            this.setState({ getOrgVal: val })
                        }} /> */}
            {/* <div className='col-fields' >
                                <Select showSearch
                                    className='text-select'
                                    placeholder="Organization"
                                    onChange={(e) => this.handleChange(e, 'org_id')}
                                    value={this.state.formData.org_id}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {listOrg}
                                </Select> 

                            </div> */}
            {/* <div className='col-fields'>
                                <Select showSearch
                                    className='text-select'
                                    mode="tags"
                                    placeholder="Choose Role"
                                    onChange={(e) => this.handleChange(e, 'role_id')}
                                    value={this.state.formData.role_id}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {listRoleTag}
                                </Select>

                            </div>  */}
            <FormItem
              label="Specialty"
              name="specialties"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please choose Specialty",
                },
                // {
                //     pattern: /^[a-zA-Z]*$/,
                //     message: 'Please enter a valid Name'

                // },
              ]}
            >
              <Select
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
                showSearch
                mode="multiple"
                className="text-select"
                placeholder="Specialty"
                onChange={(e) => this.handleChange(e, "specialties")}
                value={this.state.formData.specialties}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {listSplTag}
              </Select>
            </FormItem>
            <FormItem
              name="About_Doctor"
              label="Bio"
              className="display"
              rules={[
                {
                  required: true,
                  message: "Please fill your Bio details",
                },
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    "Please enter a valid Bio without leading or trailing spaces",
                },
                // {
                //     pattern: /^[a-zA-Z]*$/,
                //     message: 'Please enter a valid Name'

                // },
              ]}
            >
              <TextArea
                className="text-input-user-custom"
                placeholder="About Doctor"
                value={this.state.formData.about}
                onChange={(e) => this.handleChange(e.target.value, "about")}
              ></TextArea>
            </FormItem>
            {this.state.formData.type === "edit" && (
              <>
                {this.state.newPassword && (
                  <FormItem
                    name="Password"
                    label="Password"
                    className="display"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the password",
                      },
                      {
                        // pattern: /^[A-Za-z]\w{7,14}$/,
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                        message:
                          "Please enter a strong password (min 6 characters which contains atleast 1 special character,1 upper case, 1 lowecase and 1 numeric digit )",
                      },
                    ]}
                  >
                    <Input.Password
                      className="text-input-user-custom"
                      placeholder="Password"
                      onChange={(e) =>
                        this.handleChange(e.target.value, "newpassword")
                      }
                    />
                  </FormItem>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() =>
                      this.setState((prv) => {
                        return {
                          ...prv,
                          newPassword: prv.newPassword ? false : true,
                        };
                      })
                    }
                    size="large"
                  >
                    {!this.state.newPassword ? (
                      <>Update Password</>
                    ) : (
                      <>Cancel</>
                    )}
                  </Button>
                </Form.Item>
              </>
            )}
            {this.renderOrgFields()}
            {/* {this.state.userRole !== "Submitter Provider" && ( */}
            <Form.Item>
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                onClick={() => this.AddMoreOrgFeild()}
                size="middle"
              />
            </Form.Item>
            {/* )} */}
            {/* {this.state.display === true && (
              <FormItem
                label="Sub Group"
                name="subgroup"
                className="display"
                rules={[
                  {
                    required: false,
                    message: "Please choose subgroup",
                  },
                  // {
                  //     pattern: /^[a-zA-Z]*$/,
                  //     message: 'Please enter a valid Name'

                  // },
                ]}
              >
                <Select
                  showSearch
                  className="text-select"
                  placeholder="Sub Group"
                  onChange={(e) => this.handleChange(e, "subgroup")}
                  value={this.state.formData.subgroup}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {listGroupTag}
                </Select>
              </FormItem>
            )} */}
            {/* {this.state.formData.type === 'edit' && (
                            <div style={{ fontWeight: 'bold', marginLeft: '-73%'}}>$ amount:
                                <Radio.Group style={{ paddingLeft: '15px' }} >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={0}>No</Radio>
                                </Radio.Group>
                            </div> */}
            <div style={{ fontWeight: "bold", marginLeft: "-38%" }}>
              <Checkbox
                checked={this.state.formData.Dashboard_amount}
                value={this.state.formData.Dashboard_amount || 0}
                onChange={(ev) => this.getValues("Dashboard_amount", ev)}
              >
                $ amount
              </Checkbox>
              <Checkbox
                checked={this.state.formData.Dashboard_Volume}
                value={this.state.formData.Dashboard_Volume || 0}
                onChange={(ev) => this.getValues("Dashboard_Volume", ev)}
              >
                Volume
              </Checkbox>
              <Checkbox
                checked={this.state.formData.Dashboard_non_face_to_face}
                value={this.state.formData.Dashboard_non_face_to_face || 0}
                onChange={(ev) =>
                  this.getValues("Dashboard_non_face_to_face", ev)
                }
              >
                Non Face to Face %
              </Checkbox>
              <Checkbox
                checked={this.state.formData.Dashboard_Response_time}
                value={this.state.formData.Dashboard_Response_time || 0}
                onChange={(ev) => this.getValues("Dashboard_Response_time", ev)}
              >
                Response Time
              </Checkbox>
              <Checkbox
                checked={this.state.formData.responder_status}
                value={this.state.formData.responder_status || 0}
                onChange={(ev) => this.getValues("responder_status", ev)}
              >
                Responder Status
              </Checkbox>
            </div>
            {/* )} */}
            {(this.state.formData.type === "edit" ||
              this.state.formData.type === "add") && (
              <Row className="" style={{ paddingTop: "30px" }}>
                <Button
                  className="btn-type"
                  id=""
                  loading={this.state.isLoading}
                  type="primary"
                  onClick={() => this.validateForm()}
                  htmlType="submit"
                >
                  {this.state.buttonName}
                </Button>

                <Button
                  className="btn-type"
                  id=""
                  type="primary"
                  onClick={() => this.clearData()}
                >
                  Cancel
                </Button>

                {this.state.formData.type === "edit" && (
                  <Button
                    className="btn-type"
                    id=""
                    type="primary"
                    loading={this.state.resetLoading}
                    onClick={() => this.resetPassword()}
                  >
                    Reset/Send Password
                  </Button>
                )}
              </Row>
            )}
          </Form>
        </Container>
      </div>
    );
  }
}
